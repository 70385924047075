import { ComponentStyleConfig, StyleFunctionProps } from '@chakra-ui/react'

export const Badge: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 8,
    paddingInline: 1.5,
    paddingY: 1,
    textTransform: 'capitalize',
    fontWeight: 600,
    height: 7,
    fontSize: 12
  },
  variants: {
    secondary: () => ({
      border: '1px',
      borderColor: 'chakra-border-color',
      color: 'chakra-body-text'
    }),
    faded: () => ({
      border: '1px',
      borderColor: 'chakra-border-color',
      color: 'faded'
    }),
    info: (props: StyleFunctionProps) => ({
      background: props.colorMode === 'dark' ? 'color-mix(in srgb, var(--chakra-colors-brand-500) 25%, transparent)' : 'brand.50',
      color: props.colorMode === 'dark' ? 'brand.300' : 'brand.500'
    }),
    null: (props: StyleFunctionProps) => ({
      background: props.colorMode === 'dark' ? 'gray.800' : 'gray.50',
      color: props.colorMode === 'dark' ? 'gray.50' : 'gray.800'
    }),
    success: (props: StyleFunctionProps) => ({
      background: props.colorMode === 'dark' ? 'color-mix(in srgb, var(--chakra-colors-green-500) 25%, transparent)' : 'green.50',
      color: props.colorMode === 'dark' ? 'green.400' : 'green.600'
    }),
    bad: (props: StyleFunctionProps) => ({
      background: props.colorMode === 'dark' ? 'color-mix(in srgb, var(--chakra-colors-red-500) 25%, transparent)' : 'red.50',
      color: props.colorMode === 'dark' ? 'red.300' : 'red.500'
    }),
    high: (props: StyleFunctionProps) => ({
      background: props.colorMode === 'dark' ? 'color-mix(in srgb, var(--chakra-colors-red-500) 25%, transparent)' : 'red.50',
      color: props.colorMode === 'dark' ? 'red.300' : 'red.500'
    }),
    medium: (props: StyleFunctionProps) => ({
      background: props.colorMode === 'dark' ? 'color-mix(in srgb, var(--chakra-colors-orange-500) 25%, transparent)' : 'orange.50',
      color: props.colorMode === 'dark' ? 'orange.400' : 'orange.700'
    }),
    low: (props: StyleFunctionProps) => ({
      background: props.colorMode === 'dark' ? 'color-mix(in srgb, var(--chakra-colors-yellow-500) 25%, transparent)' : 'yellow.50',
      color: props.colorMode === 'dark' ? 'yellow.400' : 'yellow.800'
    })
  },
  defaultProps: {
    variant: 'solid'
  }
}
