import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import { FormProvider, useForm } from 'react-hook-form'

import { usePostTeamMutation } from '../../redux/api/teams'

type Form = {
  teamname: string
}

type Props = {
  open: boolean
  onClose: () => void
  workspaceSlug: string
}

function validateTeamName(name: string) {
  if (!name || name.length === 0) {
    return 'You must enter a team name.'
  }

  const usernameRegex = /^[a-z][a-z0-9]{0,30}$/
  if (usernameRegex.exec(name)) {
    return undefined
  }

  if (name.length > 30) {
    return 'Team name must be under 30 characters.'
  }
  return 'Team name should be lowercase and only contain letters and numbers (but cannot start with a number).'
}

export function CreateNewTeamModal({ open, onClose, workspaceSlug }: Props) {
  const [postTeam] = usePostTeamMutation()

  const methods = useForm<Form>()
  const {
    handleSubmit,
    register,
    reset,
    setError,
    watch,
    formState: { errors, isSubmitting }
  } = methods

  const teamName = watch('teamname')

  const onSubmit = async (form: Form) => {
    try {
      await postTeam({ owner: workspaceSlug, newTeam: { teamname: form.teamname } })
      onClose()
      reset()
    } catch (err: unknown) {
      const message = (err as Error)?.message || 'An error occurred. Please try again.'
      setError('teamname', { type: 'custom', message: message })
    }
  }

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>Create New Team</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Grid gap={4} templateColumns="repeat(2, 1fr)">
                <GridItem colSpan={2}>
                  <Text>A team is a set of users who can be granted access to certain projects in Mayhem.</Text>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl isRequired isInvalid={!!errors.teamname}>
                    <FormLabel>Team Name</FormLabel>
                    <Input
                      type="text"
                      placeholder="Team Name"
                      disabled={isSubmitting}
                      {...register('teamname', { maxLength: 30, validate: { validateTeamName } })}
                    />
                    <FormHelperText display="flex" flexDirection="column" gap={2}>
                      <Text fontSize="0.9em">{`${teamName?.length || 0}`}/30 characters</Text>
                      <Text>Team name should be lowercase and only contain letters and numbers (but cannot start with a number).</Text>
                    </FormHelperText>
                    <FormErrorMessage>{errors?.teamname?.message}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem justifySelf="flex-end"></GridItem>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button isLoading={isSubmitting} type="submit">
                Create
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  )
}
