import { Link as WouterLink } from 'wouter'
import { Stack } from '@chakra-ui/react'

import HomeIcon from '../../images/nav-home.svg?react'
import ProjectsIcon from '../../images/nav-projects.svg?react'
import DynamicSBOMIcon from '../../images/nav-dsbom.svg?react'
import RunsIcon from '../../images/nav-runs.svg?react'
import SettingsIcon from '../../images/nav-settings.svg?react'

import { NavButton } from '../../components/Shell/NavButton'

import { MenuSection } from './WorkspaceSidebar'
import { getSelectedRoute } from './utils'

export const WorkspaceMenu = ({ workspaceSlug, isCollapsed, submenu }: WorkspaceMenuProps) => {
  const sections: MenuSection[] = [
    {
      name: '',
      routes: [
        { path: `/${workspaceSlug}`, name: 'Dashboard', key: 'dashboard', icon: HomeIcon, extraPathMatches: [`/${workspaceSlug}/-/getting-started`] },
        {
          path: `/${workspaceSlug}/-/projects`,
          name: 'Projects',
          key: 'projects',
          icon: ProjectsIcon
        },
        { path: `/${workspaceSlug}/-/runs`, name: 'Runs', key: 'runs', icon: RunsIcon },
        {
          path: `/${workspaceSlug}/-/dynamic-sbom`,
          name: 'Dynamic SBOM',
          key: 'dsbom',
          icon: DynamicSBOMIcon,
          extraPathMatches: [`/${workspaceSlug}/-/dynamic-sbom/*?`]
        },
        {
          path: '/-/settings/user',
          name: 'Settings',
          key: 'settings',
          icon: SettingsIcon,
          extraPathMatches: [
            '/-/settings/user/*?',
            '/:workspaceSlug/-/settings/*?',
            '/:workspaceSlug/-/integrations',
            '/-/settings/admin/*?',
            '/:workspaceSlug/-/plan'
          ]
        }
      ],
      isShown: true
    }
  ]

  // highlight settings or project if those submenus are shown
  const autoSelectedRoute = getSelectedRoute(sections)
  const selectedRoute = submenu === 'settings' ? { key: 'settings' } : submenu === 'project' ? { key: 'projects' } : autoSelectedRoute

  return (
    <Stack spacing="1" alignItems={isCollapsed ? 'center' : undefined}>
      {sections.map((section) =>
        section.isShown
          ? section.routes.map((route) => {
              const button = (
                <NavButton
                  key={route.key}
                  label={route.name}
                  icon={route.icon}
                  isActive={selectedRoute?.key === route.key}
                  isCollapsed={isCollapsed}
                  onClick={route.onClick}
                />
              )

              return route.onClick ? (
                button
              ) : (
                <WouterLink key={route.key} to={route.path}>
                  {button}
                </WouterLink>
              )
            })
          : null
      )}
    </Stack>
  )
}

export interface WorkspaceMenuProps {
  workspaceSlug: string
  isCollapsed: boolean
  submenu?: 'project' | 'settings'
}
