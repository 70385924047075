import { useEffect } from 'react'

import { Container, Heading, Stack } from '@chakra-ui/react'

import { PlanDetails } from './PlanDetails'

import { getWorkspacePlanPageTitle } from '@/titles'

import { Page } from '@/components/Page'

interface Props {
  workspaceSlug: string
}

export function PlanPage({ workspaceSlug }: Props) {
  useEffect(() => {
    document.title = getWorkspacePlanPageTitle(workspaceSlug)
  }, [workspaceSlug])

  return (
    <Page>
      <Container maxW="container.lg">
        <Stack gap={8}>
          <Heading textAlign="center">Plan Details</Heading>
          <PlanDetails workspaceSlug={workspaceSlug} />
        </Stack>
      </Container>
    </Page>
  )
}
