import { useEffect } from 'react'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Card, CardBody, CardHeader, Heading, Stack } from '@chakra-ui/react'
import { Link as WouterLink, useLocation } from 'wouter'

import PermissionsList from './PermissionsList'

import { getProjectsSettingsPageTitle } from '@/titles'
import { getCurrentUserInfo } from '@/features/auth/utils'
import { useAppSelector } from '@/hooks'
import { ProjectVisibilityToggle } from '@/features/project-settings/ProjectVisibilityToggle'
import { useGetAccountQuery } from '@/redux/api/workspace'
import { useGetProjectMemberPermissionQuery } from '@/redux/api/projects'

import { Page } from '@/components/Page'
import { TruncatedTextWithTooltip } from '@/components/TruncatedTextWithTooltip'

type Props = {
  workspaceSlug: string
  projectSlug: string
}

export function AccessControlPage({ workspaceSlug, projectSlug }: Props) {
  const [location, setLocation] = useLocation()

  const { userSlug: currentUserSlug, isAdmin: isMayhemAdmin } = useAppSelector((state) => getCurrentUserInfo(state) || {})
  const { data: workspace, isLoading: isWorkspaceLoading } = useGetAccountQuery({ owner: workspaceSlug })
  const { data: projectPermission } = useGetProjectMemberPermissionQuery({
    owner: workspaceSlug,
    projectSlug,
    userSlug: currentUserSlug
  })

  const canManageTeams = workspace?.is_org

  useEffect(() => {
    document.title = getProjectsSettingsPageTitle(workspaceSlug, projectSlug, 'Access Control')
  }, [location, workspaceSlug, projectSlug, setLocation])

  const isAtLeastWritePermission = isMayhemAdmin || (projectPermission && projectPermission.permission !== 'READ')

  return (
    <Page
      header={
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
          <BreadcrumbItem>
            <WouterLink to={`/${workspaceSlug}/${projectSlug}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={projectSlug} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Access Control</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Heading mb={8}>Access Control</Heading>
      <Stack spacing={8}>
        {isAtLeastWritePermission && (
          <Card mb={8}>
            <CardHeader>
              <Heading variant="cardHeading">Project Visibility</Heading>
            </CardHeader>
            <CardBody p={8}>
              <ProjectVisibilityToggle workspaceSlug={workspaceSlug} projectSlug={projectSlug} isAdmin={isAtLeastWritePermission} />
            </CardBody>
          </Card>
        )}
        <PermissionsList
          workspaceSlug={workspaceSlug}
          projectSlug={projectSlug}
          canManageTeams={Boolean(canManageTeams)}
          isWorkspaceLoading={isWorkspaceLoading}
          isAtLeastWritePermission={Boolean(isAtLeastWritePermission)}
        />
      </Stack>
    </Page>
  )
}
