import { Container } from '@chakra-ui/react'

import { DataTab, DataTabs } from '../../components/DataTabs'
import { RunCodeDefects } from '../run-code-defects/RunCodeDefects'
import { ExploitabilityFactorsList } from '../run-code-exploitabilityfactors/ExploitabilityFactorsList'
import { RunCodeTests } from '../run-code-tests/RunCodeTests'
import { RunCodeCoverage } from '../run-code-coverage/RunCodeCoverage'
import { RunCodeLog } from '../run-code-log/RunCodeLog'
export interface RunCodePageTabsProps {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
}

export const RunCodePageTabs = ({ workspaceSlug, projectSlug, targetSlug, runNumber }: RunCodePageTabsProps) => {
  const tabs: DataTab[] = [
    {
      label: 'Defects',
      content: <RunCodeDefects workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />,
      route: `/${workspaceSlug}/${projectSlug}/${targetSlug}/${runNumber}`
    },
    {
      label: 'Tests',
      content: <RunCodeTests workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />,
      route: `/${workspaceSlug}/${projectSlug}/${targetSlug}/${runNumber}/tests`
    },
    {
      label: 'Coverage',
      content: <RunCodeCoverage workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />,
      route: `/${workspaceSlug}/${projectSlug}/${targetSlug}/${runNumber}/coverage`
    },
    {
      label: 'Exploitability Factors',
      content: <ExploitabilityFactorsList workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />,
      route: `/${workspaceSlug}/${projectSlug}/${targetSlug}/${runNumber}/exploitability`
    },
    {
      label: 'Log',
      content: <RunCodeLog workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />,
      route: `/${workspaceSlug}/${projectSlug}/${targetSlug}/${runNumber}/log`
    }
  ]

  return (
    <Container width="full" maxW="full" padding={0}>
      <DataTabs variant="line" isLazy width="full" tabs={tabs} />
    </Container>
  )
}
