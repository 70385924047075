import { Box, GridItem, HStack, Heading } from '@chakra-ui/react'

import { RunOptionsButton } from '@/features/run-code/RunOptionsButton'

export interface RunHeaderTitleProps {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  isMayhemBot: boolean
  commitSubject: string | undefined
  branchName: string | undefined
  revision: string | undefined
  isCodeRun: boolean
  isPureDocker: boolean
  dockerPullable: string
  mapiUrl: string | undefined
}

export function RunHeaderTitle({
  workspaceSlug,
  projectSlug,
  targetSlug,
  runNumber,
  isMayhemBot,
  commitSubject,
  branchName,
  revision,
  isCodeRun,
  isPureDocker,
  dockerPullable,
  mapiUrl
}: RunHeaderTitleProps) {
  let runTitle = ''
  if (isMayhemBot) {
    runTitle = 'Automatic Behavior Testing Run'
  } else if (commitSubject) {
    runTitle = `"${commitSubject}"`
  } else if (branchName && revision) {
    runTitle = `${branchName}/${revision}`
  } else if (isCodeRun && isPureDocker) {
    runTitle = dockerPullable
  } else if (mapiUrl) {
    runTitle = mapiUrl
  } else {
    // runs from `mayhem package` etc
    runTitle = `${projectSlug}/${targetSlug}/${runNumber}`
  }

  return (
    <GridItem gridArea="message">
      <HStack spacing={4}>
        <Heading display="inline-block" lineHeight={1.25} noOfLines={1} wordBreak="break-all" textOverflow="ellipsis">
          <Box>{runTitle}</Box>
        </Heading>
        <RunOptionsButton dropdownOnly workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />
      </HStack>
    </GridItem>
  )
}
