import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal, useToast } from '@chakra-ui/react'

import { useCallback, useState } from 'react'
import DeleteIcon from '@material-design-icons/svg/sharp/delete.svg?react'
import EditIcon from '@material-design-icons/svg/sharp/edit.svg?react'
import SettingsIcon from '@material-design-icons/svg/sharp/settings.svg?react'

import { useDeleteAccountWebhookMutation, useDeleteProjectWebhookMutation } from '../../redux/api/webhooks'
import { getMessageFromRtkError } from '../../util/errors'

type Props = {
  onDelete: () => void
  openWebhookModal: () => void
  projectSlug?: string
  webhookSlug: string
  workspaceSlug: string
}

export function WebhookOptionsButton({ onDelete, openWebhookModal, projectSlug, workspaceSlug, webhookSlug }: Props) {
  const toast = useToast()
  const [deleteAccountWebhook] = useDeleteAccountWebhookMutation()
  const [deleteProjectWebhook] = useDeleteProjectWebhookMutation()
  const [isDeleting, setDeleting] = useState(false)

  const handleDeleteButtonClick = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      try {
        e.stopPropagation()
        setDeleting(true)
        if (projectSlug) {
          await deleteProjectWebhook({ owner: workspaceSlug, projectSlug: projectSlug, webhookSlug }).unwrap()
        } else {
          await deleteAccountWebhook({ owner: workspaceSlug, webhookSlug }).unwrap()
        }
        toast({
          title: 'Webhook Deleted',
          description: `The webhook was deleted successfully`,
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        onDelete()
      } catch (err: unknown) {
        toast({
          title: 'Delete Webhook Failed',
          description: getMessageFromRtkError(err),
          status: 'error',
          duration: 9000,
          isClosable: true
        })
      } finally {
        setDeleting(false)
      }
    },
    [setDeleting, onDelete, toast, projectSlug, workspaceSlug, deleteAccountWebhook, deleteProjectWebhook, webhookSlug]
  )

  const handleWebhookActionsClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => e.stopPropagation(), [])

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Icon as={SettingsIcon} boxSize={6} />}
        variant="ghost"
        aria-label="Webhook Actions"
        title="Webhook Actions"
        isLoading={isDeleting}
        onClick={handleWebhookActionsClick}
      />
      <Portal>
        <MenuList>
          <MenuItem icon={<Icon as={EditIcon} />} onClick={openWebhookModal}>
            Edit
          </MenuItem>
          <MenuItem icon={<Icon as={DeleteIcon} />} onClick={handleDeleteButtonClick} isDisabled={isDeleting}>
            Delete
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  )
}
