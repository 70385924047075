import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { inputAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
  field: {
    borderRadius: '8px',
    height: 9
  }
})

export const Input = defineMultiStyleConfig({ baseStyle })
