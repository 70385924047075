import { Link as WouterLink } from 'wouter'

import { Button, Card, CardBody, CardFooter, CardHeader, CardProps, HStack, Heading, Text } from '@chakra-ui/react'

import AddIcon from '@material-design-icons/svg/sharp/add.svg?react'

export const CreateDSBOMCard = ({ workspaceSlug, ...cardProps }: CreateCodeRunCardProps) => {
  return (
    <Card {...cardProps}>
      <CardHeader>
        <Heading variant="cardHeading">Dynamic SBOM</Heading>
      </CardHeader>
      <CardBody padding={4}>
        <Text>Runtime-aware SBOM and SCA</Text>
      </CardBody>
      <CardFooter justifyContent="end">
        <HStack gap={2}>
          <WouterLink to={`/${workspaceSlug}/-/dynamic-sbom-start`}>
            <Button variant="ghost">Run with CLI</Button>
          </WouterLink>
          <WouterLink to={`/${workspaceSlug}/-/dynamic-sbom-compare`}>
            <Button leftIcon={<AddIcon />}>Get Started</Button>
          </WouterLink>
        </HStack>
      </CardFooter>
    </Card>
  )
}

export type CreateCodeRunCardProps = CardProps & {
  workspaceSlug: string
}
