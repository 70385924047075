import { Card, CardBody, CardHeader, Heading, Link as ChakraLink, ListItem, OrderedList, Stack, Text, CardFooter, Code } from '@chakra-ui/react'
import { Link as WouterLink } from 'wouter'

import { CodeBlock } from '@/components/CodeBlock/CodeBlock'
import { Page } from '@/components/Page'

interface Props {
  workspaceSlug: string
}

export function DsbomGettingStartedPage({ workspaceSlug }: Props) {
  const syncConfigText = `[sync]
upstream_url = "https://app.mayhem.security"
api_token = "YOUR TOKEN HERE"
workspace = "YOUR WORKSPACE HERE"
`
  return (
    <Page>
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Your First Dynamic SBOM Report</Heading>
        </CardHeader>
        <CardBody p={8}>
          <OrderedList spacing={8}>
            <ListItem>
              <Stack spacing={2}>
                <Text>
                  Install the{' '}
                  <ChakraLink isExternal href="https://docs.mayhem.security/dynamic-sbom/installation/" textDecoration="underline">
                    Mayhem Dynamic SBOM CLI
                  </ChakraLink>{' '}
                  and login:
                </Text>
                <CodeBlock>mdsbom login &lt;URL&gt; &lt;API_TOKEN&gt;</CodeBlock>
              </Stack>
            </ListItem>
            <ListItem>
              <Stack spacing={2}>
                <Text>Configure the container engine used for your application:</Text>
                <CodeBlock>mdsbom configure-container-engine</CodeBlock>
              </Stack>
            </ListItem>
            <ListItem>
              <Stack spacing={2}>
                <Text>
                  Configure syncing with your Mayhem instance by editing your mdsbom config in <Code>/etc/mdsbom/config.toml</Code>, by adding a
                  section like:
                </Text>
                <CodeBlock>{syncConfigText}</CodeBlock>
                <Text>
                  Replacing <Code>api_token</Code> and <Code>workspace</Code> as appropriate.
                </Text>
              </Stack>
            </ListItem>
            <ListItem>
              <Stack spacing={2}>
                <Text>Run the mdsbom report command for your desired container image, SCA report, and SBOM report:</Text>
                <CodeBlock>
                  mdsbom report --sbom-report-source &lt;INPUT_SBOM_REPORT_FILE&gt; --sca-report-source &lt;INPUT_SCA_REPORT_FILE&gt;
                  --sbom-report-out &lt;OUTPUT_SBOM_REPORT_FILE&gt; --sca-report-out &lt;OUTPUT_SCA_REPORT_FILE&gt; &lt;CONTAINER_IMAGE&gt;
                </CodeBlock>
              </Stack>
            </ListItem>
            <ListItem>
              Examine the results under{' '}
              <ChakraLink as={WouterLink} to={`/${workspaceSlug}/-/dynamic-sbom/-/reports`} textDecoration="underline">
                Dynamic SBOM - Reports
              </ChakraLink>
              .
            </ListItem>
          </OrderedList>
        </CardBody>
        <CardFooter justifyContent="end">
          <ChakraLink href="https://docs.mayhem.security/dynamic-sbom/summary/" isExternal textDecoration="underline">
            Visit our Documentation for more detailed instructions and help getting started
          </ChakraLink>
        </CardFooter>
      </Card>
    </Page>
  )
}
