import { Button, Card, CardBody, CardHeader, Heading, Text } from '@chakra-ui/react'

import { Link } from 'wouter'

import { runCommand } from './utils'

import { CodeBlock } from '@/components/CodeBlock/CodeBlock'

import { CoreApiRunConfig } from '@/features/run-api-config/CoreApiRunConfig'

export interface ApiRunPreviewProps {
  config?: CoreApiRunConfig
}

export const ApiRunPreview = ({ config }: ApiRunPreviewProps) => {
  return (
    <>
      <Text>
        <Button variant="link">
          <Link to="/-/installation">Install the CLI</Link>
        </Button>{' '}
        and create a project by running from your computer or CI pipeline:
      </Text>
      <Card marginBottom={32}>
        <CardHeader>
          <Heading variant="cardHeading">Run Mayhem</Heading>
        </CardHeader>
        <CardBody padding={8}>
          <CodeBlock>{runCommand(config)}</CodeBlock>
        </CardBody>
      </Card>
    </>
  )
}
