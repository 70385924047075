import { HStack, Box, VStack, Progress, Text, Icon, Badge } from '@chakra-ui/react'

import FileUploadIcon from '@material-design-icons/svg/outlined/upload_file.svg?react'

import { formatMemory } from '../../util/numbers'

interface DsbomComparisonFileRowProps {
  file: File
  isLoading: boolean
  isSca?: boolean
  isSbom?: boolean
}

export function DsbomComparisonFileRow({ file, isLoading, isSca, isSbom }: DsbomComparisonFileRowProps) {
  return (
    <HStack gap="3" width="100%" borderStyle="solid" borderColor="chakra-border-color" borderWidth="2px" borderRadius="15px">
      <Icon
        as={FileUploadIcon}
        boxSize="10"
        color={isLoading ? 'blue.500' : 'good.500'}
        backgroundColor={isLoading ? 'blueLight.100' : 'good.100'}
        borderRadius="50%"
        padding="2"
        marginLeft="2"
      />
      <VStack justifyContent="flex-start" alignItems="flex-start" margin="2" width="100%" gap={2}>
        <HStack justify="space-between" width="100%">
          <HStack gap={1}>
            <Text fontWeight="bold" fontSize="14">
              {file.name}
            </Text>
            {isSca && (
              <Badge colorScheme="blue" variant="outline">
                SCA
              </Badge>
            )}
            {isSbom && (
              <Badge colorScheme="blue" variant="outline">
                SBOM
              </Badge>
            )}
          </HStack>
        </HStack>
        {/* TODO [MH-16632] Handle progress bar percentage */}
        {isLoading && <Progress isIndeterminate width="100%" size="sm" borderRadius="lg" />}
        <HStack justifyContent="space-between" width="100%">
          <Box>
            <Text fontSize="12" textColor="faded">
              {formatMemory(file.size)}
            </Text>
          </Box>
          {isLoading && (
            <Box>
              <Text fontSize="12" textColor="faded">
                Upload in Progress
              </Text>
            </Box>
          )}
        </HStack>
      </VStack>
    </HStack>
  )
}
