import { Button, Card, CardBody, CardHeader, Heading, Text } from '@chakra-ui/react'
import { Link } from 'wouter'

import { mayhemRunCommand } from './utils'

import { CoreCodeRunConfig } from '@/features/run-code-config/CoreCodeRunConfig'

import { CodeBlock } from '@/components/CodeBlock/CodeBlock'

export interface CodeProjectPreviewProps {
  config: CoreCodeRunConfig
}

export const CodeRunPreview = ({ config }: CodeProjectPreviewProps) => {
  return (
    <>
      <Text>
        Or{' '}
        <Button variant="link">
          <Link to="/-/installation">Install the CLI</Link>
        </Button>{' '}
        and create a project by running from your computer or CI pipeline:
      </Text>
      <Card marginBottom={32}>
        <CardHeader>
          <Heading variant="cardHeading">Run Mayhem</Heading>
        </CardHeader>
        <CardBody padding={8}>
          <CodeBlock>{mayhemRunCommand(config)}</CodeBlock>
        </CardBody>
      </Card>
    </>
  )
}
