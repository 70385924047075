import React, { useState } from 'react'

import { Box, Button, ButtonGroup, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'

export default function TargetDeleteModal(props: {
  open: boolean
  deleteHandler: (workspaceSlug: string, projectSlug: string, projectName: string) => Promise<void>
  closeHandler: () => void
  workspaceSlug: string
  projectSlug: string
  targetName: string
}) {
  const { open, deleteHandler, closeHandler, workspaceSlug, projectSlug, targetName } = props
  const [targetNameInput, setTargetNameInput] = useState('')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTargetNameInput(e.target.value)
  }

  const onKeyUp = async (e: React.KeyboardEvent) => {
    const ENTER_KEY_CODE = 13
    if (e.keyCode === ENTER_KEY_CODE && targetNameInput === targetName) {
      await deleteHandler(workspaceSlug, projectSlug, targetName)
      closeHandler()
    }
  }

  return (
    <Modal isOpen={open} onClose={closeHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Delete Target {workspaceSlug}/{projectSlug}/{targetName}
        </ModalHeader>
        <ModalBody>
          <Text>Type the name of the target ({targetName}) in the box below to confirm deletion.</Text>
          <Text as="i">Note: this will also delete all runs for this target (including any active runs).</Text>
          <Box>
            <Input type="text" defaultValue="" placeholder={targetName} onChange={handleInputChange} onKeyUp={onKeyUp} />
          </Box>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup padding={4}>
            <Button variant="outline" onClick={closeHandler}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              isDisabled={targetNameInput !== targetName}
              onClick={async () => {
                await deleteHandler(workspaceSlug, projectSlug, targetName)
                closeHandler()
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
