import React, { useCallback, useState } from 'react'
import { IconButton, useToast } from '@chakra-ui/react'
import CloseIcon from '@material-design-icons/svg/sharp/close.svg?react'
import StopIcon from '@material-design-icons/svg/sharp/stop.svg?react'

import { usePutRunPhaseMutation } from '../../redux/api/runs'
import { getMessageFromRtkError } from '../../util/errors'

import TaskStopModal from './TaskStopModal'

export function TaskStopButton({ workspaceSlug, projectSlug, targetSlug, runNumber, isDeleteIcon = false, phaseName, isStopping }: Props) {
  const toast = useToast()
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [putRunPhaseMutation] = usePutRunPhaseMutation()
  const color = !isDeleteIcon ? 'red' : undefined

  const handleStopTaskClick = useCallback(async () => {
    try {
      await putRunPhaseMutation({
        owner: workspaceSlug,
        projectSlug,
        targetSlug,
        runNumber,
        phaseName,
        runPhase: {
          stopped: true
        }
      }).unwrap()
      toast({
        title: 'Run Stopped',
        description: `Run ${runNumber} was stopped.`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    } catch (err: unknown) {
      toast({
        title: 'Stop Run Failed',
        description: getMessageFromRtkError(err),
        status: 'error',
        duration: 9000,
        isClosable: true
      })
    }
  }, [workspaceSlug, phaseName, projectSlug, putRunPhaseMutation, runNumber, targetSlug, toast])

  return (
    <React.Fragment>
      <IconButton
        variant="outline"
        icon={isDeleteIcon ? <CloseIcon /> : <StopIcon />}
        color={color}
        aria-label={`${isDeleteIcon ? 'Cancel' : 'Stop'} Task`}
        title={`${isDeleteIcon ? 'Cancel' : 'Stop'} Task`}
        isLoading={isStopping}
        onClick={(): void => {
          setConfirmationModalOpen(true)
        }}
      />
      {confirmationModalOpen && (
        <TaskStopModal stopHandler={handleStopTaskClick} closeHandler={(): void => setConfirmationModalOpen(false)} open={confirmationModalOpen} />
      )}
    </React.Fragment>
  )
}

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  phaseName: string
  isStopping: boolean
  isDeleteIcon?: boolean
}
