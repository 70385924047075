import { useCallback, useEffect, useState } from 'react'

import { useLocation } from 'wouter'
import { Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'

import { setQueryParam, setQueryParams } from '../../util/location'

import { useQuery } from '../../hooks'

import { TestCaseReportPreview } from './TestCaseReportPreview'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  testcaseSha: string
  defectNumber?: string
  fromRunNumber?: number
  previousTestcaseSha?: string | null
  nextTestcaseSha?: string | null
  previousTestcaseRunNumber?: string // used by the 'related test cases' list on the Defect page, which could span multiple runs
  nextTestcaseRunNumber?: string // used by the 'related test cases' list on the Defect page, which could span multiple runs
}

export function TestCaseReportModal({
  workspaceSlug,
  projectSlug,
  targetSlug,
  runNumber,
  testcaseSha,
  previousTestcaseSha,
  nextTestcaseSha,
  previousTestcaseRunNumber,
  nextTestcaseRunNumber
}: Props) {
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()

  const [parentPageTitle] = useState(document.title)

  const goBackSafely = (): void => {
    // clear the testcase and run params to go back
    const returnUrl = setQueryParam({
      location,
      queryParams,
      param: 'testcase',
      value: null
    })
    setLocation(returnUrl, { replace: true })
  }

  const goToPrevTestcase = useCallback((): void => {
    const url = setQueryParams({
      location,
      queryParams,
      params: [
        { param: 'testcase', value: previousTestcaseSha },
        { param: 'run', value: previousTestcaseRunNumber }
      ]
    })
    setLocation(url, { replace: true })

    // queryParams is a new object on each render, use .toString() instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousTestcaseSha, queryParams.toString(), setLocation])

  const goToNextTestcase = useCallback((): void => {
    const url = setQueryParams({
      location,
      queryParams,
      params: [
        { param: 'testcase', value: nextTestcaseSha },
        { param: 'run', value: nextTestcaseRunNumber }
      ]
    })
    setLocation(url, { replace: true })

    // queryParams is a new object on each render, use .toString() instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTestcaseSha, queryParams.toString(), setLocation])

  const onKeydownHandler = useCallback(
    (e: KeyboardEvent): void => {
      if (e.keyCode === 37 && previousTestcaseSha !== null) {
        goToPrevTestcase()
      } else if (e.keyCode === 39 && nextTestcaseSha !== null) {
        goToNextTestcase()
      }
    },
    [goToNextTestcase, goToPrevTestcase, previousTestcaseSha, nextTestcaseSha]
  )

  useEffect(() => {
    document.addEventListener('keydown', onKeydownHandler)

    return () => document.removeEventListener('keydown', onKeydownHandler)
  }, [onKeydownHandler])

  return (
    <Modal
      isOpen
      isCentered={false}
      size="5xl"
      scrollBehavior="inside"
      onClose={() => {
        document.title = parentPageTitle
        goBackSafely()
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <TestCaseReportPreview
          {...{
            workspaceSlug,
            projectSlug,
            targetSlug,
            runNumber,
            testcaseSha
          }}
          isModal
        />
      </ModalContent>
    </Modal>
  )
}
