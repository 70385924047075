import { CardBodyProps, useToken } from '@chakra-ui/react'

import { TableCard } from '@/components/Card/TableCard'

interface Props {
  children: React.ReactNode
  actions: React.ReactNode
  title?: string | null
  CardBodyProps?: CardBodyProps
}

export function RunsTableCard({ actions, children, title = 'Runs', CardBodyProps }: Props) {
  const [paddingY] = useToken('spacing', ['padding.Y.card'])

  return (
    <TableCard actions={actions} title={title as string} CardBodyProps={{ ...CardBodyProps, paddingTop: paddingY }}>
      {children}
    </TableCard>
  )
}
