import React from 'react'

import { Card, CardBody, CardHeader, Heading, Skeleton, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'

import TestcaseShaDisplay from '../run-code/TestcaseShaDisplay'

import { DefectsRelatedValgrindErrorRow } from './DefectsRelatedValgrindErrorRow'

import ListDefaults from '@/components/ListDefaults'
import { MayhemPagination } from '@/components/MayhemPagination'
import { cleanInteger } from '@/util/numbers'
import { ValgrindError } from '@/features/valgrind-errors/types'

import { useQuery } from '@/hooks'

import { useGetValgrindErrorsByDefectQuery } from '@/redux/api/defects'
import { DEFAULT_PER_PAGE } from '@/redux/api/mayhemApi'

import { CodeBlock } from '@/components/CodeBlock/CodeBlock'

type Props = {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  defectNumber: number
  reports?: ValgrindError[]
  testcases?: unknown[]
}

export function DefectsRelatedValgrindErrors({ workspaceSlug, projectSlug, targetSlug, defectNumber }: Props) {
  const query = useQuery()
  const page = cleanInteger(query.get('page'), 1)
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)

  const {
    currentData: valgrindErrorsResponse,
    isLoading,
    isFetching,
    isError
  } = useGetValgrindErrorsByDefectQuery({
    owner: workspaceSlug,
    projectSlug,
    targetSlug,
    defectNumber,
    offset: (page - 1) * perPage,
    perPage
  })

  const { count = 0, valgrind_errors: valgrindErrorList = [] } = valgrindErrorsResponse || {}
  // Get the backtrace of the top-most item as an example
  const exampleError = valgrindErrorList[0] // Get top-most test case
  const { backtrace: exampleBacktrace, run_number: exampleRunNumber, sha256: exampleTestcaseSha } = exampleError || ({} as ValgrindError)

  return (
    <React.Fragment>
      <Card>
        {exampleBacktrace !== undefined ? (
          <React.Fragment>
            <CardHeader>
              <Heading variant="cardHeading">
                Example Backtrace (from Run {exampleRunNumber} Test Case&nbsp;
                <TestcaseShaDisplay sha256={exampleTestcaseSha} showCopyButton={false} inline />)
              </Heading>
            </CardHeader>
            <CardBody p={8}>
              <CodeBlock>{exampleBacktrace || 'Failed to acquire backtrace, possible stack corruption.'}</CodeBlock>
            </CardBody>
          </React.Fragment>
        ) : (
          <CardBody p={8}>
            <Stack spacing={4}>
              <Skeleton height={4} startColor="gray.100" endColor="gray.900" speed={0.9} fadeDuration={0.5} />
              <Skeleton height={4} startColor="gray.100" endColor="gray.900" speed={0.9} fadeDuration={0.5} />
              <Skeleton height={4} startColor="gray.100" endColor="gray.900" speed={0.9} fadeDuration={0.5} />
              <Skeleton height={4} width="80%" startColor="gray.100" endColor="gray.900" speed={0.9} fadeDuration={0.5} />
            </Stack>
          </CardBody>
        )}
      </Card>

      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Runtime Errors with This Defect</Heading>
        </CardHeader>
        <CardBody py={4}>
          <Skeleton isLoaded={!isLoading}>
            <TableContainer>
              <Table layout="fixed">
                <Thead>
                  <Tr>
                    <Th width="37.5%" textAlign="left">
                      Error
                    </Th>
                    <Th width="18.75%" textAlign="left">
                      Test Case
                    </Th>
                    <Th width="12.5%" textAlign="left">
                      Run
                    </Th>
                    <Th width="43.75%" textAlign="left">
                      Description
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {valgrindErrorList.map((valgrindError, idx) => (
                    <DefectsRelatedValgrindErrorRow
                      key={valgrindError.valgrind_error_id}
                      number={(page - 1) * perPage + idx + 1}
                      {...{ workspaceSlug, projectSlug, targetSlug, valgrindError }}
                    />
                  ))}
                  <Tr>
                    <Td colSpan={4}>
                      <ListDefaults isLoading={isFetching} nItems={valgrindErrorList.length} itemName="related error" isFail={isError} />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </Skeleton>
        </CardBody>
        <MayhemPagination isLoading={isFetching} total={count} />
      </Card>
    </React.Fragment>
  )
}
