import React from 'react'
import { Card, Stat, StatGroup, StatLabel, StatNumber } from '@chakra-ui/react'

import MayhemTableLoader from '../../components/MayhemTableLoader'
import TooltippedStatNumber from '../../components/TooltippedStatNumber'
import { useGetTargetQuery } from '../../redux/api/targets'

import { getCodeTestsuiteDisplayStat } from '../run-code/utils'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
}

export function TargetOverview({ workspaceSlug, projectSlug, targetSlug }: Props) {
  const { isLoading, data: target } = useGetTargetQuery({ owner: workspaceSlug, projectSlug, targetSlug })

  const run = target?.latest_active_run

  const nDefects = run?.n_defects
  const nCrashes = run?.n_crash_reports
  const latency = run?.api_run?.latency_p90_millis
  const nEndpoints = run?.api_run?.total_endpoint_count

  const latestRunAttributes = run?.run_attributes || {}
  const { n_optimized_testsuite_size: nOptimizedTestsuiteSizeFromRunAttribute = 0 } = (latestRunAttributes as {
    n_optimized_testsuite_size: number | undefined
  }) || {
    nOptimizedTestsuiteSizeFromRunAttribute: 0
  }
  const nTestCases = getCodeTestsuiteDisplayStat(run?.n_optimized_set_testcases || 0, nOptimizedTestsuiteSizeFromRunAttribute)

  if (isLoading) {
    return <MayhemTableLoader />
  }

  return (
    <Card p={4}>
      <StatGroup>
        <Stat border="none">
          <StatLabel>Defects</StatLabel>
          <TooltippedStatNumber value={nDefects} />
        </Stat>
        {target?.type === 'code' ? (
          <React.Fragment>
            <Stat border="none">
              <StatLabel>Crashes</StatLabel>
              <TooltippedStatNumber value={nCrashes} />
            </Stat>
            <Stat border="none">
              <StatLabel>Test Cases</StatLabel>
              <TooltippedStatNumber value={nTestCases} />
            </Stat>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Stat border="none">
              <StatLabel>p90 Latency (ms)</StatLabel>
              <StatNumber>{latency}</StatNumber>
            </Stat>
            <Stat border="none">
              <StatLabel>Endpoints</StatLabel>
              <TooltippedStatNumber value={nEndpoints} />
            </Stat>
          </React.Fragment>
        )}
      </StatGroup>
    </Card>
  )
}
