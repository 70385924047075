import { CardProps, Text, RadioProps, useRadio, Icon, Flex, Box, Stack, useColorModeValue } from '@chakra-ui/react'
import RadioButtonCheckedIcon from '@material-design-icons/svg/sharp/radio_button_checked.svg?react'
import RadioButtonUncheckedIcon from '@material-design-icons/svg/sharp/radio_button_unchecked.svg?react'

export interface RadioCardProps extends CardProps {
  title: string
  radio?: RadioProps
  isInvalid?: boolean
  isDisabled?: boolean
}

export function RadioCard({ title, isInvalid = false, radio = {}, children, ...props }: RadioCardProps) {
  const {
    state: { isChecked },
    getInputProps,
    getRadioProps
  } = useRadio(radio)
  const input = getInputProps()
  const checkbox = getRadioProps()
  const bgColor = 'pageBg'
  const bgColorChecked = useColorModeValue('brand.50', 'brand.800')
  const bgColorInvalid = 'pageBg'

  const checkedValidStyle = {
    borderColor: 'brand.600',
    backgroundColor: bgColorChecked,
    color: useColorModeValue('brand.700', 'white')
  }

  const checkedInvalidStyle = {
    borderColor: 'bad.600',
    backgroundColor: bgColorInvalid,
    color: 'bad.700'
  }

  /**
   * chakra-ui does not properly document useRadioGroup/useRadio, from examples
   * on the documentation and some good old trial-n-error I have derived that
   * the following requirements need to be met:
   * 1. a label element wraps the "checkbox" (ie the clickable component functioning as a checkbox)
   * 2. an input element is a sibling to the "checkbox"
   *
   * Even thought, these elements do not affect the display of the radio
   * buttons in a way, the radio buttons do not work without them.
   */
  return (
    <Box
      as="label"
      padding={4}
      backgroundColor={bgColor}
      borderWidth="2px"
      borderColor="faded"
      borderRadius={12}
      cursor="pointer"
      _checked={isInvalid ? checkedInvalidStyle : checkedValidStyle}
      {...checkbox}
      {...props}
    >
      <input {...input} />
      <Stack>
        <Flex alignItems="center" gap={2}>
          {isChecked && <Icon as={RadioButtonCheckedIcon} />}
          {!isChecked && <Icon as={RadioButtonUncheckedIcon} />}
          <Text fontSize="lg">{title}</Text>
        </Flex>
        {children}
      </Stack>
    </Box>
  )
}
