import { useFormContext } from 'react-hook-form'
import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'

import { CodeRunConfigBasic } from './CodeRunConfigBasic'
import { CodeRunConfigAdvanced } from './CodeRunConfigAdvanced'
import { CodeRunConfigCommands } from './CodeRunConfigCommands'
import { FormCodeRunConfig, parseCommands } from './utils'

import { formatNumber } from '@/util/numbers'

export const CodeRunConfig = ({ workspaceSlug }: { workspaceSlug: string }) => {
  // TODO(kostas): Add a warning about .sh and bash commands
  const { getValues } = useFormContext<FormCodeRunConfig>()

  const commandCount = parseCommands(getValues('cmds')).length

  return (
    <Stack spacing={4}>
      <Tabs variant="line" width="full">
        <TabList>
          <Tab>Basic</Tab>
          {commandCount > 0 && <Tab>Command ({formatNumber(commandCount)})</Tab>}
          <Tab>Advanced</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CodeRunConfigBasic workspaceSlug={workspaceSlug} />
          </TabPanel>
          {commandCount > 0 && (
            <TabPanel>
              <CodeRunConfigCommands />
            </TabPanel>
          )}
          <TabPanel>
            <CodeRunConfigAdvanced />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}
