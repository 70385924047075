import { Stack } from '@chakra-ui/react'

import { TargetDefects } from './TargetDefects'
import { TargetOverview } from './TargetOverview'

import { CardDefectTrend } from '@/components/CardDefectTrend'
import { TargetRunsList } from '@/features/target-runs/TargetRunsList'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  targetType: 'api' | 'code'
}

export function TargetOverviewPage({ workspaceSlug, projectSlug, targetSlug, targetType }: Props) {
  return (
    <Stack gap={8}>
      <TargetOverview workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} />
      <CardDefectTrend workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} />
      <TargetDefects workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} />
      <TargetRunsList workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} targetType={targetType} />
    </Stack>
  )
}
