import React, { useState } from 'react'
import CheckCircleOutlineIcon from '@material-design-icons/svg/sharp/check_circle_outline.svg?react'
import ErrorIcon from '@material-design-icons/svg/sharp/error.svg?react'
import WarningAmberIcon from '@material-design-icons/svg/sharp/warning_amber.svg?react'

import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react'

import { opaque } from '../../opaque'
import { slugifyFull } from '../../utils'
import { ApiDuration, ApiDurationOptions } from '../project-api-create/ApiDurationOptions'

import { type CoreApiRunConfig } from '../run-api-config/CoreApiRunConfig'

import { ApiRunPreview } from './ApiRunPreview'

export function CreateAPIProjectContainer(props: CreateAPIProjectProps) {
  const [apiProjectName, setApiProjectName] = useState('my awesome project')
  const [apiProjectSlug, setApiProjectSlug] = useState(slugifyFull(apiProjectName))
  const [apiTargetName, setApiTargetName] = useState('api')
  const [apiTargetSlug, setApiTargetSlug] = useState(slugifyFull(apiTargetName))

  // API Target URL
  const [url, setUrl] = useState(props.url)

  // Spec config options
  const [openApiSpec, setOpenApiSpec] = useState(props.openApiSpec)
  const [openApiSpecIsValid, setOpenApiSpecIsValid] = useState(true)
  const [postmanCollectionId, setPostmanCollectionId] = useState('')
  const [postmanCollectionIdIsValid, setPostmanCollectionIdIsValid] = useState(false)
  const [postmanEnvironmentId, setPostmanEnvironmentId] = useState('')
  const [postmanApiKey, setPostmanApiKey] = useState('')
  const [exportedPostmanCollectionPath, setExportedPostmanCollectionPath] = useState('')
  const [exportedPostmanCollectionPathIsValid, setExportedPostmanCollectionPathIsValid] = useState(false)
  const [harFilePath, setHarFilePath] = useState('')
  const [harFilePathIsValid, setHarFilePathIsValid] = useState(false)

  // Run duration
  const [duration, setDuration] = useState<ApiDuration>('auto')

  // field validation fields are below
  const urlRegex = /^(http|https):\/\/[^ "]+$/
  const specUrlOrPathRegex = /^[^ "]+$/
  // postman collection ids are of the format
  //     {{owner_id}}-{{collection_id}}
  const postmanCollectionIdRegex = /[^-]*-(.*)/
  const [urlIsValid, setUrlIsValid] = useState(true)
  const [isProjectNameValid, setIsProjectNameValid] = useState(true)
  const [isTargetNameValid, setIsTargetNameValid] = useState(true)

  // which spec tab is selected (this determines additional parameters
  // passed to the CLI)
  const [specTabIndex, setSpecTabIndex] = useState(0)
  const selectSpecTab = (activeIndex: number): void => {
    setSpecTabIndex(activeIndex)
  }

  const onProjectNameChange = (newProjectName: string) => {
    const newTrimmedProjectName = newProjectName.trim()
    setIsProjectNameValid(newTrimmedProjectName.length !== 0)
    setApiProjectName(newTrimmedProjectName)
    setApiProjectSlug(slugifyFull(newTrimmedProjectName))
  }

  const onTargetNameChange = (newTargetName: string) => {
    const newTrimmedTargetName = newTargetName.trim()
    setIsTargetNameValid(newTrimmedTargetName.length !== 0)
    setApiTargetName(newTrimmedTargetName)
    setApiTargetSlug(slugifyFull(newTrimmedTargetName))
  }
  const onUrlChange = (newUrl: string) => {
    setUrlIsValid(urlRegex.test(newUrl.trim()))
    setUrl(newUrl.trim())
  }

  const onOpenApiSpecChange = (newOpenApiSpec: string) => {
    setOpenApiSpecIsValid(specUrlOrPathRegex.test(newOpenApiSpec.trim()))
    setOpenApiSpec(newOpenApiSpec.trim())
  }
  const onPostmanCollectionIdChange = (newPostmanCollectionId: string) => {
    setPostmanCollectionIdIsValid(postmanCollectionIdRegex.test(newPostmanCollectionId.trim()))
    setPostmanCollectionId(newPostmanCollectionId.trim())
  }
  const onPostmanEnvironmentChange = (newPostmanEnvId: string) => {
    setPostmanEnvironmentId(newPostmanEnvId.trim())
  }
  const onPostmanApiKeyChange = (newPostmanApiKey: string) => {
    setPostmanApiKey(newPostmanApiKey.trim())
  }
  const onExportedPostmanCollectionChange = (newExportedCollectionPath: string) => {
    setExportedPostmanCollectionPathIsValid(specUrlOrPathRegex.test(newExportedCollectionPath.trim()))
    setExportedPostmanCollectionPath(newExportedCollectionPath.trim())
  }
  const onHarFilePathChange = (newHarFilePath: string) => {
    setHarFilePathIsValid(specUrlOrPathRegex.test(newHarFilePath.trim()))
    setHarFilePath(newHarFilePath.trim())
  }

  const onDurationChange = (value: ApiDuration) => {
    setDuration(value)
  }

  const openApiTabIdx = 0
  const postmanCollectionTabIdx = 1
  const exportedPostmanCollectionTabIdx = 2
  const harFileTabIdx = 3

  const cliSpecArg = () => {
    switch (specTabIndex) {
      case openApiTabIdx:
        return openApiSpec
      case postmanCollectionTabIdx:
        return postmanCollectionId
      case exportedPostmanCollectionTabIdx:
        return exportedPostmanCollectionPath
      case harFileTabIdx:
        return harFilePath
    }
  }

  const renderAlert = (status: 'success' | 'error' | 'warning', message: string) => {
    const alertIconsMapping = {
      success: CheckCircleOutlineIcon,
      error: ErrorIcon,
      warning: WarningAmberIcon
    }

    return (
      <Flex>
        <Alert status={status} variant="outlined" padding="2">
          <AlertIcon as={alertIconsMapping[status]} />
          {message}
        </Alert>
      </Flex>
    )
  }

  const additionalRunArgs = () => {
    const additionalArgs = []
    switch (specTabIndex) {
      case postmanCollectionTabIdx:
        if (postmanEnvironmentId.trim().length > 0) {
          additionalArgs.push('--postman-environment')
          additionalArgs.push(postmanEnvironmentId)
        }
        if (postmanApiKey.trim().length > 0) {
          additionalArgs.push('--postman-api-key')
          additionalArgs.push(postmanApiKey)
        }
    }
    return additionalArgs
  }

  return (
    <React.Fragment>
      <Stack spacing={8}>
        <Text> How do you want to keep track of this project?</Text>
        <HStack>
          <FormControl id="projectName" width="70%">
            <FormLabel>Project Name</FormLabel>
            <Input defaultValue={apiProjectName} onChange={(event) => onProjectNameChange(event.target.value)} />
            <FormHelperText>Your application name is always a good choice.</FormHelperText>
          </FormControl>
          <Flex width="30%">
            {isProjectNameValid ? (
              <Alert status="warning" variant="outlined">
                <AlertIcon as={WarningAmberIcon} />
                <Text>
                  {' '}
                  Your project will be named{' '}
                  <Box style={{ display: 'inline-block' }}>
                    <Text as="b">{apiProjectSlug}</Text>
                  </Box>
                </Text>
              </Alert>
            ) : (
              <Alert status="error">
                <AlertIcon as={ErrorIcon} />
                Not a valid project name
              </Alert>
            )}
          </Flex>
        </HStack>

        <HStack>
          <FormControl id="targetName" width="70%">
            <FormLabel>Target Name</FormLabel>
            <Input defaultValue={apiTargetName} onChange={(event) => onTargetNameChange(event.target.value)} />
            <FormHelperText>The name of your API service within the project.</FormHelperText>
          </FormControl>
          <Flex width="30%">
            {isTargetNameValid ? (
              <Alert status="warning" variant="outlined">
                <AlertIcon as={WarningAmberIcon} />
                <Text>
                  {' '}
                  Your target will be named{' '}
                  <Box style={{ display: 'inline-block' }}>
                    <Text as="b">{apiTargetSlug}</Text>
                  </Box>
                </Text>
              </Alert>
            ) : (
              <Alert status="error">
                <AlertIcon as={ErrorIcon} />
                Not a valid target name
              </Alert>
            )}
          </Flex>
        </HStack>

        <HStack>
          <FormControl id="url" width="70%">
            <FormLabel>URL</FormLabel>
            <Input defaultValue={url} onChange={(event) => onUrlChange(event.target.value)} />
            <FormHelperText>URL of the API you want to test.</FormHelperText>
          </FormControl>
          <Flex width="30%">{urlIsValid ? renderAlert('success', 'URL looks good!') : renderAlert('error', 'Not a valid http[s]://* URL')}</Flex>
        </HStack>
        <HStack>
          <FormControl id="url">
            <FormLabel>API Specification</FormLabel>
            <Tabs variant="line" onChange={selectSpecTab} index={specTabIndex}>
              <TabList>
                <Tab>OpenAPI/Swagger</Tab>
                <Tab>Postman Collection</Tab>
                <Tab>Exported Postman Collection</Tab>
                <Tab>HAR (HTTP Archive)</Tab>
              </TabList>
              <TabPanels>
                {/* 0 - OpenAPI / Swagger */}
                <TabPanel>
                  <FormControl id="openApiSpec">
                    <FormLabel>Path to specification</FormLabel>
                    <Flex gap={2}>
                      <Input width="80%" defaultValue={openApiSpec} onChange={(event) => onOpenApiSpecChange(event.target.value)} />
                      {openApiSpecIsValid ? renderAlert('success', 'Spec looks good!') : renderAlert('error', 'Does not look like a valid spec')}
                    </Flex>
                    <FormHelperText>Path to an OpenAPI 3.* or Swagger 2.0 YAML or JSON file. URL or path to local file.</FormHelperText>
                  </FormControl>
                </TabPanel>

                {/* 1 - Postman Collection */}
                <TabPanel>
                  <FormControl id="postmanCollection">
                    <FormLabel>Postman Collection ID</FormLabel>
                    <Flex gap={2}>
                      <Input width="80%" onChange={(event) => onPostmanCollectionIdChange(event.target.value)} />
                      {postmanCollectionIdIsValid
                        ? renderAlert('success', 'ID looks good!')
                        : renderAlert('error', 'Does not look like Postman Collection ID')}
                    </Flex>
                    <FormHelperText marginBottom={4}>
                      <a href="https://support.postman.com/hc/en-us/articles/5063785095319-How-to-find-the-ID-of-an-element-in-Postman">
                        Postman Collection ID
                      </a>
                    </FormHelperText>

                    <FormLabel>Postman Environment</FormLabel>
                    <Flex gap={2}>
                      <Input width="80%" onChange={(event) => onPostmanEnvironmentChange(event.target.value)} />
                    </Flex>
                    <FormHelperText marginBottom={4}>
                      (Optional) <a href="https://learning.postman.com/docs/sending-requests/managing-environments/">Postman Environment ID</a>, or
                      path to JSON file to use for Postman Collections
                    </FormHelperText>

                    <FormLabel>Postman API Key </FormLabel>
                    <Flex gap={2}>
                      <Input width="80%" onChange={(event) => onPostmanApiKeyChange(event.target.value)} />
                    </Flex>
                    <FormHelperText marginBottom={4}>(Optional) Postman API key for collection access</FormHelperText>
                  </FormControl>
                </TabPanel>

                {/* 2 - Exported Postman Collection */}
                <TabPanel>
                  <FormControl id="exportedPostmanCollection">
                    <FormLabel>Path to exported Postman Collection</FormLabel>
                    <Flex gap={2}>
                      <Input width="80%" onChange={(event) => onExportedPostmanCollectionChange(event.target.value)} />
                      {exportedPostmanCollectionPathIsValid
                        ? renderAlert('success', 'Path looks good!')
                        : renderAlert('error', 'Does not look like a path')}
                    </Flex>
                    <FormHelperText>Path to an exported Postman 2.x collection on local file system</FormHelperText>
                  </FormControl>
                </TabPanel>

                {/* 3 - HAR File */}
                <TabPanel>
                  <FormControl id="harFileSpec">
                    <FormLabel>Path to HTTP Archive file</FormLabel>
                    <Flex gap={2}>
                      <Input width="80%" onChange={(event) => onHarFilePathChange(event.target.value)} />
                      {harFilePathIsValid ? renderAlert('success', 'Path looks good!') : renderAlert('error', 'Does not look like a valid path')}
                    </Flex>
                    <FormHelperText>Path to an HTTP Archive (.har) on local file system</FormHelperText>
                  </FormControl>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </FormControl>
        </HStack>
        <FormControl id="duration">
          <FormLabel>Duration</FormLabel>
          <FormHelperText marginBottom={4}>Longer explores your app more deeply.</FormHelperText>
          <ApiDurationOptions value={duration} onChange={onDurationChange} />
        </FormControl>
        <ApiRunPreview
          config={opaque<CoreApiRunConfig>({
            workspaceSlug: props.workspaceSlug,
            projectSlug: apiProjectSlug,
            targetSlug: apiTargetSlug,
            api: { url: url, spec: cliSpecArg()! },
            additionalRunArgs: additionalRunArgs(),
            duration: duration
          })}
        />
      </Stack>
    </React.Fragment>
  )
}

export interface CreateAPIProjectProps {
  workspaceSlug: string
  url: string
  openApiSpec: string
}
