import { useEffect } from 'react'
import { Link as WouterLink } from 'wouter'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Stack } from '@chakra-ui/react'

import { TeamMembersCard } from './TeamMembersCard'

import { AddMembersCard } from './AddMembersCard'

import { getTeamPageTitle } from '@/titles'

import { Page } from '@/components/Page'

import { usePermissions } from '@/hooks'

type Props = {
  workspaceSlug: string
  team: string
}

export function TeamPage({ workspaceSlug, team: teamSlug }: Props) {
  const { allowWrite: userCanAddMembersToTeam } = usePermissions(workspaceSlug)

  useEffect(() => {
    document.title = getTeamPageTitle(workspaceSlug, teamSlug)
  }, [workspaceSlug, teamSlug])

  return (
    <Page
      header={
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbLink as={WouterLink} to={`/${workspaceSlug}/-/settings`}>
              Workspace Settings
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{teamSlug}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Stack spacing={8}>
        {userCanAddMembersToTeam && <AddMembersCard workspaceSlug={workspaceSlug} team={teamSlug} />}
        <TeamMembersCard workspaceSlug={workspaceSlug} teamSlug={teamSlug} />
      </Stack>
    </Page>
  )
}
