import { Action } from 'redux'

import { ListReducerStateType, ProjectType, TargetType } from '../../types'

// ///////////////////////////////////////////////////////////////////
/* USERS */
// ///////////////////////////////////////////////////////////////////

export const REQUEST_USERS_LIST = 'REQUEST_USERS_LIST'
export const RECEIVE_USERS_LIST = 'RECEIVE_USERS_LIST'
export const CLEAR_USERS_LIST = 'CLEAR_USERS_LIST'
export const SET_USERS_LIST_PAGE = 'SET_USERS_LIST_PAGE'
export const SET_USERS_LIST_MAX_PAGE = 'SET_USERS_LIST_MAX_PAGE'
export const SET_USERS_LIST_FILTER = 'SET_USERS_LIST_FILTER'
export const RECEIVE_SET_USER_STATS = 'RECEIVE_SET_USER_STATS'

export interface ApiUserType {
  active: boolean
  avatar: string | null
  createdAt: string
  emailAddress: string
  firstName: string
  hasOauthLogin: boolean
  isAdmin: boolean
  isModerator: boolean
  language: string | null
  lastActivity: string | null
  lastLoginAt: number | null
  lastSeenOnboardingVersion: number
  lastSeenOnboardingAt: number | null
  lastName: string
  location: string | null
  owner: string
  slug: string
  updatedAt: string
  userId: number
  username: string
  userSlug: string
  status: string
  invitedBy: string | null
  inviteDate: string | null
  inviteStatus: string | null
}

export type User = ApiUserType & {
  emailVerified: boolean
  firstName: string
  inviteDate: number
  invitedBy: string
  isFetching: boolean
  isSettingActiveStatus: boolean
  isSettingPermissionLevel: boolean
  isSettingResourceQuota: boolean
  lastName: string
  maxActiveRuns: number
  maxRunDuration: number
  setActiveStatusSuccess: boolean
  setPermissionLevelSuccess: boolean
  setResourceQuotaSuccess: boolean
  userSlug: unknown
}

export interface UsersReducerStateType {
  usersList?: ListReducerStateType
  totalUsers: number

  // enrollment settings
  accountEnrollmentEnabled: boolean
  isAccountEnrollmentToggleLoading: boolean
  localEnrollmentEnabled: boolean
  googleEnrollmentEnabled: boolean
  oktaEnrollmentEnabled: boolean
  githubEnrollmentEnabled: boolean
  keycloakEnabled: boolean
  googleEnrollmentAvailable: boolean
  oktaEnrollmentAvailable: boolean
  githubEnrollmentAvailable: boolean
  keycloakAvailable: boolean
  passwordStrengthEnabled: boolean
  remoteAccessEnabled: boolean
  enrollmentDomainAllowlist: string
  isEnrollmentConfigLoading: boolean

  // resource limitation quota
  maxActiveRuns?: number
  maxRunDuration?: number

  // mayhem bot settings
  mbotTaskDuration: number
  mbotUtilization: number
  mbotReserve: number
  mbotOwner: string
  mbotSlug: string
}

type ClearUsersListActionType = Action<typeof CLEAR_USERS_LIST>

interface SetUsersListPageActionType {
  type: typeof SET_USERS_LIST_PAGE
  page: number
}

interface SetUsersListMaxPageActionType {
  type: typeof SET_USERS_LIST_MAX_PAGE
  page: number
}

interface SetUsersListFilterActionType {
  type: typeof SET_USERS_LIST_FILTER
  filters: { query: string | null }
}

type RequestUsersListActionType = Action<typeof REQUEST_USERS_LIST>
interface ReceiveUsersListActionType {
  type: typeof RECEIVE_USERS_LIST
  items: Array<ApiUserType>
  isFail: boolean
}
interface SetUserStatActionType {
  type: typeof RECEIVE_SET_USER_STATS
  totalUsers: number
}

// ///////////////////////////////////////////////////////////////////
/* USERS - ACCOUNT ENROLLMENT */
// ///////////////////////////////////////////////////////////////////

export const SET_ENROLLMENT_CONFIG = 'SET_ENROLLMENT_CONFIG'
export const SET_ENROLLMENT_CONFIG_LOADING = 'SET_ENROLLMENT_CONFIG_LOADING'
export const SET_ENROLLMENT_CONFIG_VALUE = 'SET_ENROLLMENT_CONFIG_VALUE'

export interface UserSettingResponse {
  // enrollment settings
  localEnrollmentEnabled: boolean
  googleOauthEnabled: boolean
  oktaOauthEnabled: boolean
  githubOauthEnabled: boolean
  keycloakEnabled: boolean
  googleOauthAvailable: boolean
  oktaOauthAvailable: boolean
  githubOauthAvailable: boolean
  keycloakAvailable: boolean
  passwordStrengthEnabled: boolean
  userDelegationEnabled: boolean
  enrollmentDomainAllowlist: string
  emailSendingAvailable: boolean

  // resource limitation quota
  maxActiveRuns?: number
  maxRunDuration?: number

  // mayhem bot settings
  mbotTaskDuration: number
  mbotUtilization: number
  mbotReserve: number
  mbotOwner: string
  mbotSlug: string
}

export type EnrollmentType =
  | 'oktaEnrollmentEnabled'
  | 'googleEnrollmentEnabled'
  | 'githubEnrollmentEnabled'
  | 'keycloakEnabled'
  | 'localEnrollmentEnabled'
  | 'passwordStrengthEnabled'
  | 'remoteAccessEnabled'
  | 'enrollmentDomainAllowlist'
  | 'dockerRegistryScopingEnabled'

interface SettEnrollmentConfigActionType {
  type: typeof SET_ENROLLMENT_CONFIG
  enrollmentSettings: UserSettingResponse
}

interface SetEnrollmentConfigValueActionType {
  type: typeof SET_ENROLLMENT_CONFIG_VALUE
  enrollmentType: EnrollmentType
  value: boolean | string
}

interface SetEnrollmentConfigLoadingActionType {
  type: typeof SET_ENROLLMENT_CONFIG_LOADING
  isLoading: boolean
}

export type UsersActionsType =
  | RequestUsersListActionType
  | ReceiveUsersListActionType
  | SetUsersListPageActionType
  | SetUsersListMaxPageActionType
  | SetUsersListFilterActionType
  | ClearUsersListActionType
  | SettEnrollmentConfigActionType
  | SetEnrollmentConfigValueActionType
  | SetEnrollmentConfigLoadingActionType
  | SetUserStatActionType

// ///////////////////////////////////////////////////////////////////
/* SYSTEM RESOURCE QUOTA */
// ///////////////////////////////////////////////////////////////////

export const SET_SYSTEM_RESOURCE_QUOTA = 'SET_SYSTEM_RESOURCE_QUOTA'

export interface SystemResourceQuotaType {
  maxActiveRuns?: number
  maxRunDuration?: number
}

interface SetSystemResourceQuotaActionType {
  type: typeof SET_SYSTEM_RESOURCE_QUOTA
  quota: Partial<SystemResourceQuotaType>
}

export type SystemResourceQuotaActionType = SetSystemResourceQuotaActionType

// ///////////////////////////////////////////////////////////////////
/* MAYHEM BOT SETTINGS */
// ///////////////////////////////////////////////////////////////////

export const SET_MAYHEM_BOT_SETTINGS = 'SET_MAYHEM_BOT_SETTINGS'

export interface MayhemBotSettings {
  mbotSlug: string
  mbotOwner: number
  mbotUtilization: number
  mbotReserve: number
  mbotTaskDuration: number
}

export interface MayhemBotSettingsEditable {
  mbotUtilization: number
}

export interface SetMayhemBotSettingsActionType {
  type: typeof SET_MAYHEM_BOT_SETTINGS
  payload: MayhemBotSettingsEditable
}

// ///////////////////////////////////////////////////////////////////
/* WEBHOOKS */
// ///////////////////////////////////////////////////////////////////

export const CLEAR_WEBHOOKS_LIST = 'CLEAR_WEBHOOKS_LIST'
export const CLOSE_WEBHOOK_MODAL = 'CLOSE_WEBHOOK_MODAL'
export const OPEN_WEBHOOK_MODAL = 'OPEN_WEBHOOK_MODAL'
export const RECEIVE_DELETE_WEBHOOK = 'RECEIVE_DELETE_WEBHOOK'
export const RECEIVE_TEST_WEBHOOK = 'RECEIVE_TEST_WEBHOOK'
export const RECEIVE_WEBHOOK = 'RECEIVE_WEBHOOK'
export const RECEIVE_WEBHOOKS_LIST = 'RECEIVE_WEBHOOKS_LIST'
export const REQUEST_DELETE_WEBHOOK = 'REQUEST_DELETE_WEBHOOK'
export const REQUEST_TEST_WEBHOOK = 'REQUEST_TEST_WEBHOOK'
export const REQUEST_WEBHOOK = 'REQUEST_WEBHOOK'
export const REQUEST_WEBHOOKS_LIST = 'REQUEST_WEBHOOKS_LIST'
export const SET_WEBHOOKS_FILTER = 'SET_WEBHOOKS_FILTER'
export const SET_WEBHOOKS_MAX_PAGE = 'SET_WEBHOOKS_MAX_PAGE'
export const SET_WEBHOOKS_PAGE = 'SET_WEBHOOKS_PAGE'

export type WebhookFormatType = 'json_v1' | 'slack' | 'gchat'

export interface WebhookSubscriptionType {
  owner: string
  projectSlug?: string
}

export interface WebhookFormType {
  subscription?: WebhookSubscriptionType
  url?: string
  name?: string
  format?: WebhookFormatType
  slug?: string
  run_start?: boolean
  testcase_new?: boolean
  run_complete?: boolean
  crash_new?: boolean
  defect_new?: boolean
  defect_found?: boolean
}

export interface WebhookItemType extends WebhookFormType {
  createdAt: string
  lastTriggeredAt: string | null
  updatedAt: string
  runStart?: boolean
  testcaseNew?: boolean
  runComplete?: boolean
  crashNew?: boolean
  defectNew?: boolean
  defectFound?: boolean
}

export interface WebhookItemReducerType extends WebhookItemType {
  isFetching: boolean
  isDeleting: boolean
  isTestWebhookFetching: boolean
  isTestWebhookFail: boolean
}

export interface WebhooksReducerStateType {
  webhooksList: ListReducerStateType | null
  webhooksById: { [key: string]: WebhookItemReducerType }
  isWebhookModalOpen: boolean
  preloadFormData: Partial<WebhookItemType> | null
}

type ClearWebhooksListActionType = Action<typeof CLEAR_WEBHOOKS_LIST>

type RequestWebhooksListActionType = Action<typeof REQUEST_WEBHOOKS_LIST>
interface ReceiveWebhooksListActionType {
  type: typeof RECEIVE_WEBHOOKS_LIST
}

interface ReceiveDeleteWebhookActionType {
  type: typeof RECEIVE_DELETE_WEBHOOK
  webhookId: string
  isFail: boolean
}

interface RequestDeleteWebhookActionType {
  type: typeof REQUEST_DELETE_WEBHOOK
  webhookId: string
}

interface RequestWebhookActionType {
  type: typeof REQUEST_WEBHOOK
  webhookId: string
}

interface ReceiveWebhookActionType {
  type: typeof RECEIVE_WEBHOOK
  webhook: WebhookItemType
  webhookId: string
}

interface SetWebhooksFilterActionType {
  type: typeof SET_WEBHOOKS_FILTER
  filters: Record<string, never>
}

interface SetWebhooksListPageActionType {
  type: typeof SET_WEBHOOKS_PAGE
  page: number
}

interface SetWebhooksListMaxPageActionType {
  type: typeof SET_WEBHOOKS_MAX_PAGE
  page: number
}

type CloseWebhookModalActionType = Action<typeof CLOSE_WEBHOOK_MODAL>
interface OpenWebhookModalActionType {
  type: typeof OPEN_WEBHOOK_MODAL
  preloadFormData?: Partial<WebhookItemType>
}

interface RequestTestWebhookActionType {
  type: typeof REQUEST_TEST_WEBHOOK
  webhookId: string
}

interface ReceiveTestWebhookActionType {
  type: typeof RECEIVE_TEST_WEBHOOK
  webhookId: string
  isFail?: boolean
}

export type WebhookActionsType =
  | ClearWebhooksListActionType
  | RequestWebhooksListActionType
  | ReceiveWebhooksListActionType
  | ReceiveDeleteWebhookActionType
  | RequestDeleteWebhookActionType
  | SetWebhooksFilterActionType
  | SetWebhooksListPageActionType
  | SetWebhooksListMaxPageActionType
  | RequestWebhookActionType
  | ReceiveWebhookActionType
  | OpenWebhookModalActionType
  | CloseWebhookModalActionType
  | RequestTestWebhookActionType
  | ReceiveTestWebhookActionType

// ///////////////////////////////////////////////////////////////////
/* BECOME USER */
// ///////////////////////////////////////////////////////////////////

export const REQUEST_BECOME_USER = 'REQUEST_BECOME_USER'
export const RECEIVE_BECOME_USER = 'RECEIVE_BECOME_USER'

interface RequestBecomeUserActionType {
  type: typeof REQUEST_BECOME_USER
  userSlug: string
}

interface ReceiveBecomeUserActionType {
  type: typeof RECEIVE_BECOME_USER
  userSlug: string
}

export type BecomeUserActionsType = RequestBecomeUserActionType | ReceiveBecomeUserActionType

// ///////////////////////////////////////////////////////////////////
/* REPORTING */
// ///////////////////////////////////////////////////////////////////

export const REQUEST_REPORTING_STATS = 'REQUEST_REPORTING_STATS'
export const RECEIVE_REPORTING_STATS = 'RECEIVE_REPORTING_STATS'

export type ReportingTimeFilterType =
  | 'today'
  | 'yesterday'
  | 'last_week'
  | 'this_week'
  | 'last_month'
  | 'this_month'
  | 'last_quarter'
  | 'this_year'
  | 'last_year'
  | 'all_time'
  | undefined

export interface OtherStatsType {
  elapsedCpuSeconds?: number
  averageCpuUsage?: number
  averageMemoryUsage?: number

  numNewTestcases?: number
  numNewDefectiveTestcases?: number
  numNewCrashingTestcases?: number

  numNewProjects?: number
  numActiveProjects?: number
  numTotalProjects?: number

  numNewTargets?: number
  numActiveTargets?: number
  numTotalTargets?: number

  numNewRuns?: number
  numActiveRuns?: number
  numTotalRuns?: number

  numNewDefects?: number
  numTotalDefects?: number

  deltaDefectsFound?: number
  deltaCpuHours?: number

  numNewUsers?: number
}

export interface ApiOtherStatsType {
  elapsedCpuSeconds: number
  averageCpuUsage: number
  averageMemoryUsage: number

  nNewTestcases: number
  nNewDefectiveTestcases: number
  nNewCrashingTestcases: number

  nNewProjects: number
  nActiveProjects: number
  nTotalProjects: number

  nNewTargets: number
  nActiveTargets: number
  nTotalTargets: number

  nNewRuns: number
  nActiveRuns: number
  nTotalRuns: number

  nNewDefects: number
  nTotalDefects: number

  deltaDefectsFound: number
  deltaCpuHours: number

  nNewUsers?: number
}

export interface ReportingStatType {
  label: string
  value: number
}

interface TopItemType {
  seconds: number
}

export interface TopProjectType extends ProjectType, TopItemType {}

export interface TopTargetType extends TargetType, TopItemType {}

export interface ApiReportingDataType {
  startTime: number
  endTime: number
  otherStats: ApiOtherStatsType
  defectsByType: Array<ReportingStatType>
  targetsByLanguage: Array<ReportingStatType>
  topProjects: Array<TopProjectType>
  topTargets: Array<TopTargetType>
}

export interface ReportingDataType {
  startTime: number
  endTime: number
  otherStats: OtherStatsType
  defectsByType?: Array<ReportingStatType>
  targetsByLanguage?: Array<ReportingStatType>
  topProjects?: Array<TopProjectType>
  topTargets?: Array<TopTargetType>
}

export interface ReportingReducerStateType extends ReportingDataType {
  isFetching: boolean
  isFetchFail: boolean
  ready: boolean
}

type RequestReportingStatsActionType = Action<typeof REQUEST_REPORTING_STATS>
interface ReceiveReportingStatsActionType {
  type: typeof RECEIVE_REPORTING_STATS
  isFail?: boolean
  json?: ApiReportingDataType
}

export type ReportingActionsType = RequestReportingStatsActionType | ReceiveReportingStatsActionType
