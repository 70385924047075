import React, { useState } from 'react'

import { Button, ButtonGroup, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text } from '@chakra-ui/react'

export default function WorkspaceDeleteModal(props: {
  open: boolean
  deleteHandler: (workspaceSlug: string) => Promise<void>
  closeHandler: () => void
  workspaceSlug: string
}) {
  const { open, deleteHandler, closeHandler, workspaceSlug } = props
  const [workspaceNameInput, setWorkspaceNameInput] = useState('')

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkspaceNameInput(e.target.value)
  }

  const onKeyUp = async (e: React.KeyboardEvent) => {
    const ENTER_KEY_CODE = 13
    if (e.keyCode === ENTER_KEY_CODE && workspaceNameInput === workspaceSlug) {
      await deleteHandler(workspaceSlug)
      closeHandler()
    }
  }

  return (
    <Modal isOpen={open} onClose={closeHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Workspace {workspaceSlug}</ModalHeader>
        <ModalBody>
          <Stack spacing={4}>
            <Text>Type the short name of the workspace ({workspaceSlug}) in the box below to confirm deletion.</Text>
            <Text as="i">
              Note: this will also delete all data for the workspace, including projects, runs and generated test cases. All members of the workspace
              will be removed.
            </Text>
            <Input type="text" defaultValue="" placeholder={workspaceSlug} onChange={handleInputChange} onKeyUp={onKeyUp} />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="outline" onClick={closeHandler}>
              Cancel
            </Button>
            <Button
              colorScheme="bad"
              isDisabled={workspaceNameInput !== workspaceSlug}
              onClick={async () => {
                await deleteHandler(workspaceSlug)
                closeHandler()
              }}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
