import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Stack } from '@chakra-ui/react'

import { setMruWorkspace } from './mru.slice'

import { WorkspaceStats } from './WorkspaceStats'

import { CardDefectTrend } from '@/components/CardDefectTrend'
import WorkspaceProjectsList from '@/features/workspace-projects/WorkspaceProjectsList'

type Props = { workspaceSlug: string }

export function WorkspaceNonZeroDashboard({ workspaceSlug }: Props) {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setMruWorkspace(workspaceSlug))
  }, [workspaceSlug, dispatch])

  return (
    <Stack spacing={8}>
      <CardDefectTrend workspaceSlug={workspaceSlug} />
      <WorkspaceStats workspaceSlug={workspaceSlug} />
      <WorkspaceProjectsList workspaceSlug={workspaceSlug} title="Latest Results" />
    </Stack>
  )
}
