import React from 'react'
import { Badge, Card, CardBody, Flex, Heading, HStack, Icon, Link, Skeleton, Stack, Text, VStack } from '@chakra-ui/react'

import { Feature } from 'flagged'

import { ErrorPanel } from '../../components/ErrorPanel'
import { useGetDefectByTargetQuery } from '../../redux/api/defects'

import { getDefectIcon } from '../defect-report/utils'
import { JiraIssue } from '../defect-jira/JiraIssue'

import { FEATURE_LIFECYCLE_MANAGEMENT } from '../../featureFlags'

import { DefectSeverityBadge } from './DefectSeverityBadge'
import { DefectSeverityExplainer } from './DefectSeverityExplainer'
import { DefectStatusControls } from './DefectStatusControls'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  defectNumber: number
  testcaseSha?: string | null
}

export function CodeDefectSummary({ workspaceSlug, projectSlug, targetSlug, defectNumber }: Props) {
  const {
    isLoading: isDefectLoading,
    data: defect,
    isError: defectIsError
  } = useGetDefectByTargetQuery({ owner: workspaceSlug, projectSlug, targetSlug, defectNumber })

  if (defectIsError) {
    return <ErrorPanel msg="We can't find that defect for you. Sorry about that!" />
  }

  const { severity_level: severityLevel, severity, title, cwe_description, cwe_link, cwe_number, cwe_type } = defect || {}

  return (
    <React.Fragment>
      <Skeleton isLoaded={!isDefectLoading}>
        <Flex direction={{ base: 'column', lg: 'row' }} gap="2" justifyContent="space-between">
          <VStack justifyContent="flex-start" alignItems="flex-start">
            <HStack>
              <Icon as={getDefectIcon(cwe_number ?? undefined)} boxSize={8} />
              <Heading size="lg">{title}</Heading>
              <Text color="faded">Defect #{defectNumber}</Text>
              <DefectSeverityBadge severityLevel={severityLevel} severity={severity} />
              <DefectSeverityExplainer />
            </HStack>
            <JiraIssue defectNumber={defectNumber} workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} />
          </VStack>
          <Feature name={FEATURE_LIFECYCLE_MANAGEMENT}>
            <DefectStatusControls workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} />
          </Feature>
        </Flex>
      </Skeleton>
      <Card>
        <CardBody p={8}>
          <Heading size="md">CWE Details</Heading>
          <Flex direction="row" alignItems="center" marginTop={8} gap={4}>
            <Badge variant="secondary" marginX={4}>
              <Link href={cwe_link} target="_blank" rel="noopener noreferrer">
                CWE-{cwe_number}
              </Link>
            </Badge>
            <Stack>
              <Text fontSize="lg">{cwe_type}</Text>
              <Text>{cwe_description}</Text>
            </Stack>
          </Flex>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
