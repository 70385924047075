import { useEffect } from 'react'

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, Stack } from '@chakra-ui/react'
import { Link as WouterLink } from 'wouter'

import { Page } from '../../components/Page'
import { TruncatedTextWithTooltip } from '../../components/TruncatedTextWithTooltip'
import { useGetDefectByTargetQuery } from '../../redux/api/defects'
import { getDefectPageTitle } from '../../titles'

import { ErrorPage } from '../../components/layout/ErrorPage'

import { CodeDefectSummary } from './CodeDefectSummary'
import { DefectNavigation } from './DefectNavigation'
import { DefectsRelatedTestCases } from './DefectsRelatedTestCases'
import { DefectsRelatedValgrindErrors } from './DefectsRelatedValgrindErrors'
import { RestIssue } from './RestIssue'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  defect: string
  runNumber: number | null | undefined
}

export function DefectPage({ workspaceSlug, projectSlug, targetSlug, defect: defectNumberParam, runNumber }: Props) {
  let defectNumber: number
  try {
    defectNumber = Number.parseInt(defectNumberParam, 10)
  } catch {
    throw new Error(`Invalid defect number: ${defectNumberParam}`)
  }

  const { data: defect, isLoading, isError, error } = useGetDefectByTargetQuery({ owner: workspaceSlug, projectSlug, targetSlug, defectNumber })
  useEffect(() => {
    document.title = getDefectPageTitle(workspaceSlug, projectSlug, targetSlug, defectNumberParam)
  }, [workspaceSlug, projectSlug, targetSlug, defectNumber, defectNumberParam])

  const isMapiIssue = defect?.type === 'mapi' || defect?.type === 'zap'
  const isCodeIssue = defect?.type === 'crash' || defect?.type === undefined || defect?.type === 'valgrind'
  const isValgrindIssue = defect?.type === 'valgrind' || defect?.from_valgrind

  if (isError && error) {
    const goBackLocation = `/${workspaceSlug}`
    if (error && 'status' in error) {
      if (error.status === 404) {
        return <ErrorPage errorCode={404} errorMessage="We couldn't find the specified defect" goBackLocation={goBackLocation} />
      } else {
        return <ErrorPage errorMessage="An error occurred while fetching the defect" goBackLocation={goBackLocation} />
      }
    }

    return <ErrorPage errorMessage="An error occurred while fetching the defect" goBackLocation={goBackLocation} />
  }

  return (
    <Page
      isLoading={isLoading}
      header={
        <HStack alignItems="center">
          <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
            <BreadcrumbItem>
              <WouterLink to={`/${workspaceSlug}/${projectSlug}`}>
                <BreadcrumbLink>
                  <TruncatedTextWithTooltip text={projectSlug} />
                </BreadcrumbLink>
              </WouterLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <WouterLink to={`/${workspaceSlug}/${projectSlug}/${targetSlug}`}>
                <BreadcrumbLink>
                  <TruncatedTextWithTooltip text={targetSlug} />
                </BreadcrumbLink>
              </WouterLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink>Defect #{defectNumber}</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <DefectNavigation
            workspaceSlug={workspaceSlug}
            projectSlug={projectSlug}
            targetSlug={targetSlug}
            runNumber={runNumber}
            defectNumber={defectNumber}
          />
        </HStack>
      }
    >
      <Stack spacing={8}>
        {isCodeIssue && (
          <CodeDefectSummary workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} />
        )}
        {isCodeIssue && !isValgrindIssue && (
          <DefectsRelatedTestCases workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} />
        )}
        {isValgrindIssue && (
          <DefectsRelatedValgrindErrors workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} defectNumber={defectNumber} />
        )}
        {isMapiIssue && (
          <RestIssue
            workspaceSlug={workspaceSlug}
            projectSlug={projectSlug}
            targetSlug={targetSlug}
            defectNumber={defectNumber}
            runNumber={runNumber}
          />
        )}
      </Stack>
    </Page>
  )
}
