import { useEffect } from 'react'

import { getWorkspacePageTitle } from '../../titles'

import { Page } from '../../components/Page'

import { WorkersFullNotice } from '../runs/WorkersFullNotice'

import { WorkspaceRunsList } from './WorkspaceRunsList'

interface Props {
  workspaceSlug: string
}

export function WorkspaceRunsPage({ workspaceSlug }: Props) {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspaceSlug, 'Runs')
  }, [workspaceSlug])

  return (
    <Page header="Runs">
      <WorkersFullNotice />
      <WorkspaceRunsList workspaceSlug={workspaceSlug} />
    </Page>
  )
}
