import { Button, Card, CardBody, CardHeader, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, Tooltip, useDisclosure } from '@chakra-ui/react'

import { useState } from 'react'

import { UserPermissionsTable } from './UserPermissionsTable'
import { TeamPermissionsTable } from './TeamPermissionsTable'
import { UserPermissionsModal } from './UserPermissionsModal'
import { TeamPermissionsModal } from './TeamPermissionsModal'

interface Props {
  workspaceSlug: string
  projectSlug: string
  canManageTeams: boolean
  isWorkspaceLoading: boolean
  isAtLeastWritePermission: boolean
}

export default function PermissionsList({ workspaceSlug, projectSlug, canManageTeams, isWorkspaceLoading, isAtLeastWritePermission }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">Contributors</Heading>
        {isAtLeastWritePermission && <Button onClick={onOpen}>Add {tabIndex === 0 ? 'User' : 'Team'}</Button>}
      </CardHeader>
      <CardBody py={4}>
        <Tabs variant="enclosed" isLazy width="full" tabIndex={tabIndex} onChange={(index) => setTabIndex(index)}>
          {/* TODO: Replace 8 with padding.X.card once PR 4323 is merged */}
          <TabList paddingLeft={8}>
            <Tab fontWeight={600}>Users</Tab>
            <Tab isDisabled={isWorkspaceLoading || !canManageTeams} fontWeight={600}>
              <Tooltip
                isDisabled={canManageTeams}
                label={`The project "${projectSlug}" is a part of the ${workspaceSlug} personal account. Teams can only be created and added to projects belonging to organizations.`}
              >
                Teams
              </Tooltip>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel paddingInline={0}>
              <UserPermissionsTable workspaceSlug={workspaceSlug} projectSlug={projectSlug} currentUserHasPermission={isAtLeastWritePermission} />
            </TabPanel>
            <TabPanel paddingInline={0}>
              <TeamPermissionsTable workspaceSlug={workspaceSlug} projectSlug={projectSlug} currentUserHasPermission={isAtLeastWritePermission} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CardBody>
      <UserPermissionsModal workspaceSlug={workspaceSlug} projectSlug={projectSlug} open={tabIndex === 0 && isOpen} onClose={onClose} />
      <TeamPermissionsModal workspaceSlug={workspaceSlug} projectSlug={projectSlug} open={tabIndex === 1 && isOpen} onClose={onClose} />
    </Card>
  )
}
