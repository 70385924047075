import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Skeleton,
  Stack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  Tooltip
} from '@chakra-ui/react'

import RunCoverageButton from './RunCoverageButton'

import { useGetRunQuery } from '@/redux/api/runs'
import { formatNumber } from '@/util/numbers'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
}

export function RunCodeCoverage({ workspaceSlug, projectSlug, targetSlug, runNumber }: Props) {
  const { isLoading: isLoadingRun, data: run } = useGetRunQuery({ owner: workspaceSlug, projectSlug, targetSlug, runNumber })

  if (isLoadingRun) {
    return <Skeleton height={80} />
  }

  const {
    phases: tasks = [],
    tests_per_second: inputsTestedPerSecond = 0,
    edges_covered: nBehaviorsObserved = 0,
    tests_run: nInputsTested = 0,
    run_attributes: runAttributes = null
  } = run || {}
  const coverageTask = tasks.find((x) => x.phase_name === 'coverage_analysis')
  const hasCoverageTask = coverageTask !== undefined
  const coverageTaskCompleted = !!coverageTask?.ended_at

  const {
    n_blocks_covered: nBlocksCovered = 0,
    n_blocks_total: nBlocksTotal = 0,
    n_functions_covered: nFunctionsCovered = 0,
    n_functions_total: nFunctionsTotal = 0,
    n_lines_covered: nLinesCovered = 0,
    n_lines_total: nLinesTotal = 0
  } = (runAttributes || {}) as {
    n_blocks_covered?: number
    n_blocks_total?: number
    n_functions_covered?: number
    n_functions_total?: number
    n_lines_covered?: number
    n_lines_total?: number
  }

  const getCoverageDisplays = (covered: number, total: number, type: string) => {
    let value
    let tooltip
    if (total) {
      value = `${((covered / total) * 100).toFixed(2)}%`
      tooltip = `${formatNumber(covered, { shorten: false })} / ${formatNumber(total, { shorten: false })} ${type}s covered during this run`
    } else {
      value = '--'
      tooltip = coverageTaskCompleted
        ? `${type} coverage not available for this run - to obtain ${type} coverage, compile your target with debug symbols present`
        : `${type} coverage will be available upon completion of this run`
    }
    return [value, tooltip]
  }

  const [blocksValue, blocksTooltip] = getCoverageDisplays(nBlocksCovered, nBlocksTotal, 'dynamic block')
  const [functionsValue, functionsTooltip] = getCoverageDisplays(nFunctionsCovered, nFunctionsTotal, 'function')
  const [linesValue, linesTooltip] = getCoverageDisplays(nLinesCovered, nLinesTotal, 'line')

  const showLinesAlert = coverageTaskCompleted && !nLinesTotal

  return (
    <Stack gap={4}>
      {!hasCoverageTask && (
        <Alert status="info" variant="subtle">
          <AlertIcon />
          <AlertTitle>
            Coverage stats and reports incomplete due to Coverage Analysis not being selected as a task for this run. Enable Coverage Analysis as a
            task in order to have Mayhem perform this analysis.
          </AlertTitle>
        </Alert>
      )}
      {hasCoverageTask && showLinesAlert && (
        <Alert status="warning">
          <AlertIcon />
          <AlertTitle>Unable to Calculate Line Coverage</AlertTitle>
          <AlertDescription>You must compile your application with debug symbols in order for Mayhem to provide line coverage data.</AlertDescription>
        </Alert>
      )}
      <StatGroup gap={4} justifyContent="start">
        <Card p={4}>
          <Stat border={0}>
            <StatLabel>Line Coverage</StatLabel>
            <Tooltip label={linesTooltip}>
              <StatNumber>{linesValue}</StatNumber>
            </Tooltip>
          </Stat>
        </Card>
        <Card p={4}>
          <Stat border={0}>
            <StatLabel>Dynamic Block Coverage</StatLabel>
            <Tooltip label={blocksTooltip}>
              <StatNumber>{blocksValue}</StatNumber>
            </Tooltip>
          </Stat>
        </Card>
        <Card p={4}>
          <Stat border={0}>
            <StatLabel>Function Coverage</StatLabel>
            <Tooltip label={functionsTooltip}>
              <StatNumber>{functionsValue}</StatNumber>
            </Tooltip>
          </Stat>
        </Card>
        <Card p={4}>
          <Stat border={0}>
            <StatLabel>Behaviors Observed</StatLabel>
            <Tooltip
              label={`${formatNumber(nBehaviorsObserved, {
                shorten: false
              })} behaviors in your application discovered during Behavior Testing, indicating overall features of the specified target such as code coverage, edge coverage, etc.`}
            >
              <StatNumber>{formatNumber(nBehaviorsObserved)}</StatNumber>
            </Tooltip>
          </Stat>
        </Card>
        <Card p={4}>
          <Stat border={0}>
            <StatLabel>Inputs Tested</StatLabel>
            <Tooltip
              label={`${formatNumber(nInputsTested, { shorten: false })} total inputs Mayhem has run through your application during this run.`}
            >
              <StatNumber>{formatNumber(nInputsTested)}</StatNumber>
            </Tooltip>
          </Stat>
        </Card>
        <Card p={4}>
          <Stat border={0}>
            <StatLabel>Inputs Tested Per Second</StatLabel>
            <Tooltip label="The rate at which Mayhem is able to test new inputs on your application. ">
              <StatNumber>{inputsTestedPerSecond}</StatNumber>
            </Tooltip>
          </Stat>
        </Card>
      </StatGroup>
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Coverage Files</Heading>
        </CardHeader>
        <CardBody p={8}>
          <Stack spacing={4}>
            <Text>
              Mayhem provides a package of files for line (.lcov), dynamic block (.drcov), and function (.json) coverage. See Analyzing Code Coverage
              in the documentation for more details on using these files to analyze the code explored by Mayhem.
            </Text>
            <RunCoverageButton
              workspaceSlug={workspaceSlug}
              projectSlug={projectSlug}
              targetSlug={targetSlug}
              runNumber={runNumber}
              fullButton
              disabled={!coverageTaskCompleted}
            />
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  )
}
