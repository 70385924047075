import { Skeleton } from '@chakra-ui/react'

import { useGetRunQuery } from '../../redux/api/runs'

import { ProgressIndicator, ProgressIndicatorProps } from '../../components/ProgressIndicator/ProgressIndicator'

import { getRunStateFromStatusBools } from '../run-code/utils'

export const RunStatusSpinner = ({ indicatorSize = 'sm', workspaceSlug, projectSlug, targetSlug, runNumber }: Props) => {
  const { isLoading, data: run } = useGetRunQuery({ owner: workspaceSlug, projectSlug, targetSlug, runNumber })

  const { is_deleted: isDeleted = false, pending = false, started = false, stopped = false, ended = false, failed = false } = run || {}

  const runState = getRunStateFromStatusBools({
    stopped,
    failed,
    ended,
    started,
    pending,
    isDeleted
  })

  // TODO: remove data-testid attribute once ProgressIndicator is accessible
  return (
    <Skeleton isLoaded={!isLoading && !!run}>
      <ProgressIndicator variant={runState.name} size={indicatorSize} data-testid="progressIndicator" />
    </Skeleton>
  )
}

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  indicatorSize: ProgressIndicatorProps['size']
}
