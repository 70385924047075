import { useEffect } from 'react'

import { Heading } from '@chakra-ui/react'
import { Redirect } from 'wouter'

import { getWorkspacePageTitle } from '../../titles'

import { MayhemPageLoader } from '../../components/MayhemPageLoader'
import { Page } from '../../components/Page'
import { useGetAccountStatsQuery } from '../../redux/api/workspace'

import { ErrorPage } from '../../components/layout/ErrorPage'

import { WorkspaceNonZeroDashboard } from './WorkspaceNonZeroDashboard'

type Props = { workspaceSlug: string }

export function WorkspaceDashboardPage({ workspaceSlug }: Props) {
  const { isLoading, isError, data } = useGetAccountStatsQuery({
    owner: workspaceSlug
  })

  useEffect(() => {
    document.title = getWorkspacePageTitle(workspaceSlug, 'Dashboard')
    /* TODO ideally
    dispatch(setMruWorkspace(workspace))
    // here, but, currently, if user had a typo in URL, we'll store the typo :(
    // so, setting workspace.mru in the "next step" instead.
    */
  }, [workspaceSlug])

  if (isError) {
    return <ErrorPage errorMessage={`We can't find that workspace for you. Sorry about that!`} goBackLocation="/" />
  }

  const renderContent = () => {
    if (isLoading) {
      return <MayhemPageLoader />
    }

    if (data?.project_count && data?.project_count > 0) {
      return <WorkspaceNonZeroDashboard workspaceSlug={workspaceSlug} />
    }

    return <Redirect to={`/${workspaceSlug}/-/getting-started`} />
  }

  return <Page header={<Heading>Dashboard</Heading>}>{renderContent()}</Page>
}
