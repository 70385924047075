import PermissionDeleteAction from './PermissionDeleteAction'

import { useDeleteProjectUserMemberMutation } from '@/redux/api/projects'

interface Props {
  onDelete: () => void
  userSlug: string
  workspaceSlug: string
  projectSlug: string
  currentUserHasPermission: boolean
}

export default function UserPermissionDeleteAction({ onDelete, userSlug, projectSlug, workspaceSlug, currentUserHasPermission }: Props) {
  const [deleteProjectUser, { isLoading: isDeleting }] = useDeleteProjectUserMemberMutation()

  return (
    <PermissionDeleteAction
      entity="User"
      slug={userSlug}
      currentUserHasPermission={currentUserHasPermission}
      isDeleting={isDeleting}
      projectSlug={projectSlug}
      onDelete={onDelete}
      deleteAction={async () => {
        await deleteProjectUser({ owner: workspaceSlug, projectSlug, userSlug }).unwrap()
      }}
    />
  )
}
