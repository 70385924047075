import { ComponentStyleConfig } from '@chakra-ui/react'

import { StyleFunctionProps } from '@chakra-ui/theme-tools'

import { autocompleteAnatomy } from '../../components/Autocomplete/anatomy'

export const Autocomplete: ComponentStyleConfig = {
  parts: autocompleteAnatomy.keys,
  baseStyle: (props: StyleFunctionProps) => ({
    input: {},
    list: {
      position: 'absolute',
      bg: 'chakra-body-bg',
      border: '1px',
      borderColor: 'inherit',
      borderRadius: 'xl',
      padding: '1',
      // render this over top of any LinkOverlay components
      // which have zIndex=1
      zIndex: 2
    },
    item: {
      px: '4',
      py: '2',
      cursor: 'pointer',
      transition: 'background-color 220ms, color 220ms',
      _hover: {
        bg: props.colorMode === 'light' ? `${props.colorScheme}.100` : `${props.colorScheme}.400`
      },
      _highlighted: {
        bg: props.colorMode === 'light' ? `${props.colorScheme}.100` : `${props.colorScheme}.400`
      }
    }
  })
}
