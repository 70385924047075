import { useEffect } from 'react'

import { Skeleton } from '@chakra-ui/react'

import { ErrorPanel } from '../../components/ErrorPanel'
import { useGetDefectByTargetQuery } from '../../redux/api/defects'
import { getDefectPageTitle } from '../../titles'

import { CodeDefectPreview } from './CodeDefectPreview'
import { ApiDefectPreview } from './ApiDefectPreview'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  defectNumber: number
  runNumber?: number
  isModal?: boolean
}

export function DefectPreview({ workspaceSlug, projectSlug, targetSlug, defectNumber, runNumber, isModal = false }: Props) {
  const { data: defect, isFetching, isError } = useGetDefectByTargetQuery({ owner: workspaceSlug, projectSlug, targetSlug, defectNumber })
  useEffect(() => {
    document.title = getDefectPageTitle(workspaceSlug, projectSlug, targetSlug, defectNumber.toString())
  }, [workspaceSlug, projectSlug, targetSlug, defectNumber])

  if (isFetching) {
    return <Skeleton height={80} borderRadius={isModal ? 'md' : '2xl'} />
  }

  const isMapiIssue = defect?.type === 'mapi' || defect?.type === 'zap'
  const isCodeIssue = defect?.type === 'crash' || defect?.type === undefined || defect?.type === 'valgrind'
  return (
    <>
      {isError && <ErrorPanel msg="We can't find that defect for you. Sorry about that!" />}
      {isCodeIssue && (
        <CodeDefectPreview
          workspaceSlug={workspaceSlug}
          projectSlug={projectSlug}
          targetSlug={targetSlug}
          defectNumber={defectNumber}
          runNumber={runNumber}
          isModal={isModal}
        />
      )}
      {isMapiIssue && (
        <ApiDefectPreview
          workspaceSlug={workspaceSlug}
          projectSlug={projectSlug}
          targetSlug={targetSlug}
          defectNumber={defectNumber}
          runNumber={runNumber}
          isModal={isModal}
        />
      )}
    </>
  )
}
