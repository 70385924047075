import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'

import ArrowDropDownIcon from '@material-design-icons/svg/sharp/arrow_drop_down.svg?react'

import { DownloadFileButton } from '../../components/DownloadFileButton'

import { useGetMdsbomDerivedReportSetQuery } from '@/redux/api/mdsbom'

import { constructReportFileDownloadUrl } from '@/util/mdsbom'

interface Props {
  workspaceSlug: string
  reportId: string
}

export function DsbomReportDownloadMenuButton({ workspaceSlug, reportId }: Props) {
  const { data: relatedReports } = useGetMdsbomDerivedReportSetQuery({ workspace: workspaceSlug, reportId })

  const sbomReportDownloadCsvLink = `${constructReportFileDownloadUrl(workspaceSlug, reportId)}_csv`
  const sbomDerivedReportLink = constructReportFileDownloadUrl(workspaceSlug, relatedReports?.sbom_derived_report_id)
  const scaDerivedReportLink = constructReportFileDownloadUrl(workspaceSlug, relatedReports?.sca_derived_report_id)
  const sourceSbomLink = constructReportFileDownloadUrl(workspaceSlug, relatedReports?.source_sbom_report_id)
  const sourceScaLink = constructReportFileDownloadUrl(workspaceSlug, relatedReports?.source_sca_report_id)

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<ArrowDropDownIcon />} variant="ghost">
        Download
      </MenuButton>
      <MenuList>
        <MenuItem>
          <DownloadFileButton
            label="Derived SBOM"
            href={sbomDerivedReportLink || ''}
            isDisabled={!sbomDerivedReportLink}
            variant="ghost"
            aria-label="Download Annotated SBOM File"
            colorScheme="black"
          />
        </MenuItem>
        <MenuItem>
          <DownloadFileButton
            label="Derived SCA"
            href={scaDerivedReportLink || ''}
            isDisabled={!scaDerivedReportLink}
            variant="ghost"
            aria-label="Download Annotated SCA File"
            colorScheme="black"
          />
        </MenuItem>
        <MenuItem>
          <DownloadFileButton
            label="Source SBOM"
            href={sourceSbomLink || ''}
            isDisabled={!sourceSbomLink}
            variant="ghost"
            aria-label="Download Source SBOM File"
            colorScheme="black"
          />
        </MenuItem>
        <MenuItem>
          <DownloadFileButton
            label="Source SCA"
            href={sourceScaLink || ''}
            isDisabled={!sourceScaLink}
            variant="ghost"
            aria-label="Download Source SCA File"
            colorScheme="black"
          />
        </MenuItem>
        <MenuItem>
          <DownloadFileButton
            label="SBOM Report CSV"
            href={sbomReportDownloadCsvLink}
            variant="ghost"
            aria-label="Download SBOM Report CSV"
            colorScheme="black"
            download={`sbom_report_${new Date().toISOString()}.csv`}
          />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
