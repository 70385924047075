import { useState } from 'react'
import { Box, Card, CardBody, CardHeader, Heading } from '@chakra-ui/react'

import { DefectTrend } from './chart/DefectTrendChart/DefectTrend'
import { FilterBarOption } from './chart/DefectTrendChart/filterBarOption'

import { useGetDefectTrendQuery } from '@/redux/api/defects'

export interface CardDefectTrendsProps {
  workspaceSlug: string
  projectSlug?: string
  targetSlug?: string
}

export const CardDefectTrend = ({ workspaceSlug, projectSlug, targetSlug }: CardDefectTrendsProps) => {
  const [{ start, end, step }, setTrendOptions] = useState(getFilterOptions('30 days'))
  const { isLoading, data: trend } = useGetDefectTrendQuery({
    owner: workspaceSlug,
    project: projectSlug,
    target: targetSlug,
    windowStartTimestampSeconds: start,
    windowEndTimestampSeconds: end,
    windowStepSeconds: step
  })

  const handleFilterChange = ({ nextValue }: { nextValue: FilterBarOption }) => {
    setTrendOptions(getFilterOptions(nextValue))
    return Promise.resolve()
  }

  const data = (trend?.trend || []).map((point) => {
    return {
      timestamp: new Date(point.timestamp as string),
      fixed: point.fixed as number,
      remaining: point.active as number
    }
  })

  return (
    <Card>
      <CardHeader>
        <Heading variant="cardHeading">Defect Trend</Heading>
      </CardHeader>
      <CardBody>
        <Box height="xs" m={4}>
          <DefectTrend data={data} isLoading={isLoading} defaultFilterOption="30 days" onChange={handleFilterChange} />
        </Box>
      </CardBody>
    </Card>
  )
}

function getFilterOptions(filter: FilterBarOption) {
  const currentTimestamp = Math.floor(new Date().getTime() / 1000)
  // No need to refresh more often than every 15 minutes
  const timestamp = Math.floor(currentTimestamp / 60 / 15) * 15 * 60

  const hours = 60 * 60
  const days = 24 * hours
  const months = 30 * days

  switch (filter) {
    case '12 months':
      return { start: timestamp - 365 * days, end: timestamp, step: 7 * days }
    case '3 months':
      return { start: timestamp - 3 * months, end: timestamp, step: 7 * days }
    case '30 days':
      return { start: timestamp - 30 * days, end: timestamp, step: days }
    case '7 days':
      return { start: timestamp - 7 * days, end: timestamp, step: days }
    case '24 hours':
    default:
      return { start: timestamp - 24 * hours, end: timestamp, step: hours }
  }
}
