import { useEffect } from 'react'
import { Breadcrumb, BreadcrumbItem, Heading, SimpleGrid } from '@chakra-ui/react'

import { getProjectPageTitle } from '../../titles'
import { CardDefectTrend } from '../../components/CardDefectTrend'

import { useGetProjectQuery } from '../../redux/api/projects'

import { ErrorPage } from '../../components/layout/ErrorPage'

import { ProjectOverviewStats } from './ProjectOverviewStats'

import { Page } from '@/components/Page'
import { TruncatedTextWithTooltip } from '@/components/TruncatedTextWithTooltip'
import { ProjectTargetsList } from '@/features/target/TargetsForProjectList'

type Props = {
  workspaceSlug: string
  projectSlug: string
}

export function ProjectOverviewPage({ workspaceSlug, projectSlug }: Props) {
  const { isLoading, isError, error } = useGetProjectQuery({
    owner: workspaceSlug,
    projectSlug
  })

  useEffect(() => {
    document.title = getProjectPageTitle(workspaceSlug, projectSlug)
  }, [workspaceSlug, projectSlug])

  if (isError && error) {
    const goBackLocation = `/${workspaceSlug}`
    if ('status' in error) {
      if (error.status === 404) {
        return <ErrorPage errorCode={404} errorMessage="We couldn't find the specified project" goBackLocation={goBackLocation} />
      } else {
        return <ErrorPage errorMessage="An error occurred while fetching the project" goBackLocation={goBackLocation} />
      }
    }

    return <ErrorPage errorMessage="An error occurred while fetching the project" goBackLocation={goBackLocation} />
  }

  return (
    <Page
      isLoading={isLoading}
      header={
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
          <BreadcrumbItem isCurrentPage>
            <TruncatedTextWithTooltip text={projectSlug} />
          </BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Heading mb={8}>{projectSlug}</Heading>
      <SimpleGrid columns={{ base: 1, xl: 2 }} gap={6} mb={8}>
        <CardDefectTrend workspaceSlug={workspaceSlug} projectSlug={projectSlug} />
        <ProjectOverviewStats workspaceSlug={workspaceSlug} projectSlug={projectSlug} />
      </SimpleGrid>

      <ProjectTargetsList workspaceSlug={workspaceSlug} projectSlug={projectSlug} />
    </Page>
  )
}
