import { ComponentStyleConfig } from '@chakra-ui/react'
import { StyleFunctionProps } from '@chakra-ui/theme-tools'

export const Menu: ComponentStyleConfig = {
  baseStyle: (props: StyleFunctionProps) => {
    return {
      list: {
        bg: 'chakra-body-bg'
      },
      item: {
        bg: 'chakra-body-bg',
        _hover: {
          bg: props.colorMode === 'dark' ? `${props.colorScheme}.400` : `${props.colorScheme}.100`
        }
      }
    }
  }
}
