import { Button, useDisclosure, useToast } from '@chakra-ui/react'

import { useCallback } from 'react'
import { useLocation } from 'wouter'

import WorkspaceDeleteModal from './WorkspaceDeleteModal'

import { useDeleteOrgMutation } from '@/redux/api/workspace'

import { getMessageFromRtkError } from '@/util/errors'

type Props = {
  userSlug: string
  workspaceSlug: string
}

export function WorkspaceDeleteButton({ userSlug, workspaceSlug }: Props) {
  const [_location, setLocation] = useLocation()
  const toast = useToast()
  const { isOpen: isDeleteConfirmationOpen, onOpen: onDeleteConfirmationOpen, onClose: onDeleteConfirmationClose } = useDisclosure()
  const [deleteWorkspace] = useDeleteOrgMutation()
  const onDeleteWorkspace = useCallback(async () => {
    try {
      toast({
        title: 'Workspace Deleted',
        description: `${workspaceSlug} was deleted`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
      setLocation(`/${userSlug}`) // Personal workspace cannot be deleted - safe to go here
      await deleteWorkspace({ orgSlug: workspaceSlug }).unwrap()
    } catch (err: unknown) {
      const message = getMessageFromRtkError(err)
      toast({
        title: 'Delete Workspace Failed',
        description: message,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
  }, [toast, deleteWorkspace, userSlug, workspaceSlug, setLocation])

  return (
    <>
      <Button colorScheme="red" variant="ghost" onClick={onDeleteConfirmationOpen}>
        Delete Workspace
      </Button>
      {isDeleteConfirmationOpen && userSlug && workspaceSlug && (
        <WorkspaceDeleteModal
          workspaceSlug={workspaceSlug}
          deleteHandler={onDeleteWorkspace}
          closeHandler={onDeleteConfirmationClose}
          open={isDeleteConfirmationOpen}
        />
      )}
    </>
  )
}
