import { useEffect, useMemo } from 'react'
import { Link } from 'wouter'
import { Button, Card, CardBody, CardHeader, Heading, Icon, Skeleton, Stack, Text } from '@chakra-ui/react'
import ArrowCircleRightIcon from '@material-design-icons/svg/sharp/arrow_circle_right.svg?react'

import { GeneralTab } from './GeneralTab'

import UsersList from './UsersList'

import TeamsList from './TeamsList'

import { ErrorPanel } from '@/components/ErrorPanel'
import { useGetAccountQuery } from '@/redux/api/workspace'
import { getWorkspacePageTitle } from '@/titles'

import { ScrollableSettingsPage } from '@/features/settings/ScrollableSettingsPage'

import { usePermissions } from '@/hooks'

import { Page } from '@/components/Page'

interface Props {
  workspaceSlug: string
}

export function WorkspaceSettingsPage({ workspaceSlug }: Props) {
  const { isLoading, isError, error, data } = useGetAccountQuery({ owner: workspaceSlug })

  const { allowWrite } = usePermissions(workspaceSlug)

  useEffect(() => {
    document.title = getWorkspacePageTitle(workspaceSlug, 'Settings')
  }, [workspaceSlug])

  const { is_org: isOrg } = { ...data }

  const sections = useMemo(
    () => [
      {
        id: 'general',
        component: <GeneralTab allowWrite={allowWrite} workspaceSlug={workspaceSlug} />
      },
      {
        id: 'users',
        component: <UsersList allowWrite={allowWrite} workspaceSlug={workspaceSlug} />
      },
      {
        id: 'teams',
        component: <TeamsList allowWrite={allowWrite} workspaceSlug={workspaceSlug} />,
        isShown: !!allowWrite // Hide if the user doesn't have write permissions
      }
    ],
    [allowWrite, workspaceSlug]
  )

  if (isLoading) {
    return <Skeleton height="16rem" />
  }

  if (isError && 'status' in error) {
    const message = (error?.data as { message: string } | undefined)?.message
    return <ErrorPanel msg={message || 'Failed to access workspace'} />
  } else if (isError) {
    return <ErrorPanel msg="Failed to access workspace" />
  }

  if (isOrg) {
    return <ScrollableSettingsPage title="Workspace Settings" sections={sections} />
  } else {
    return (
      <Page>
        <Card variant="warning" mb={8}>
          <CardHeader>
            <Heading variant="cardHeading">Not available for Personal Workspace</Heading>
          </CardHeader>
          <CardBody p={4}>
            <Stack spacing={4}>
              <Text>Members can only be managed in a shared workspace.</Text>
              <Text>
                Please choose a different workspace, or{' '}
                <Button variant="link" rightIcon={<Icon as={ArrowCircleRightIcon} />}>
                  <Link to="/-/settings/create-workspace">create a new workspace</Link>
                </Button>
                .
              </Text>
            </Stack>
          </CardBody>
        </Card>
      </Page>
    )
  }
}
