import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, HStack, IconButton } from '@chakra-ui/react'
import { Link as WouterLink, useLocation } from 'wouter'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

import { TruncatedTextWithTooltip } from '../../components/TruncatedTextWithTooltip'
import { useGetRunQuery } from '../../redux/api/runs'

export interface RunBreadcrumbProps {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  runNumberAsLink?: boolean
}

export const RunBreadcrumb = ({ workspaceSlug, projectSlug, targetSlug, runNumber, runNumberAsLink }: RunBreadcrumbProps) => {
  const { data: run } = useGetRunQuery({
    owner: workspaceSlug,
    projectSlug: projectSlug,
    targetSlug: targetSlug,
    runNumber
  })

  const [_location, setLocation] = useLocation()

  const previous_run_number = run?.previous_run?.run_number
  const next_run_number = run?.next_run_number

  const navigateToRun = (toRunNumber: number) => {
    setLocation(`/${workspaceSlug}/${projectSlug}/${targetSlug}/${toRunNumber}`)
  }

  return (
    <HStack alignItems="center">
      <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
        <BreadcrumbItem>
          <WouterLink to={`/${workspaceSlug}/${projectSlug}`}>
            <BreadcrumbLink>
              <TruncatedTextWithTooltip text={run?.project_name || projectSlug} />
            </BreadcrumbLink>
          </WouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <WouterLink to={`/${workspaceSlug}/${projectSlug}/${targetSlug}`}>
            <BreadcrumbLink>
              <TruncatedTextWithTooltip text={run?.target_name || targetSlug} />
            </BreadcrumbLink>
          </WouterLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage={!runNumberAsLink}>
          {runNumberAsLink ? (
            <BreadcrumbLink as={WouterLink} to={`/${workspaceSlug}/${projectSlug}/${targetSlug}/${runNumber}`}>
              Run {runNumber}
            </BreadcrumbLink>
          ) : (
            <BreadcrumbLink>Run {runNumber}</BreadcrumbLink>
          )}
        </BreadcrumbItem>
      </Breadcrumb>
      <HStack>
        {previous_run_number && (
          <IconButton variant="outline" icon={<ChevronLeftIcon />} onClick={() => navigateToRun(previous_run_number)} aria-label="Previous Run" />
        )}
        {next_run_number && (
          <IconButton variant="outline" icon={<ChevronRightIcon />} onClick={() => navigateToRun(next_run_number)} aria-label="Next Run" />
        )}
      </HStack>
    </HStack>
  )
}
