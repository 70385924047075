import { ComponentStyleConfig, StyleFunctionProps } from '@chakra-ui/react'

import { progressIndicatorAnatomy } from '../../components/ProgressIndicator/anatomy'
import { rotate } from '../animations'

export const ProgressIndicator: ComponentStyleConfig = {
  parts: progressIndicatorAnatomy.keys,
  baseStyle: {
    outer: {
      borderWidth: '4px',
      borderRadius: '50%',
      width: '100%',
      height: '100%'
    },
    inner: {
      borderRadius: '50%',
      width: '85%',
      height: '85%'
    },
    container: {
      position: 'relative',
      gridColumn: '1 / span 2',
      display: 'grid'
    },
    spinner: {
      position: 'absolute',
      display: 'none',

      maskImage: `url('/spinner.svg')`,
      maskSize: 'cover',
      width: '120%',
      height: '120%',

      transformOrigin: 'center center',
      transformBox: 'fill-box',
      animation: `${rotate} 1.5s ease infinite`
    },
    icon: {
      position: 'absolute',
      color: 'chakra-body-bg'
    }
  },
  sizes: {
    sm: {
      container: {
        width: '30px',
        height: '30px'
      },
      outer: {
        borderWidth: '2px'
      },
      icon: {
        width: '12px',
        height: '12px'
      }
    },
    md: {
      container: {
        width: '40px',
        height: '40px'
      },
      outer: {
        borderWidth: '3px'
      },
      icon: {
        width: '16px',
        height: '16px'
      }
    },
    lg: {
      container: {
        width: '80px',
        height: '80px'
      },
      icon: {
        width: '32px',
        height: '32px'
      }
    }
  },
  variants: {
    pending: {
      spinner: {
        display: 'block',
        background: 'faded'
      }
    },
    running: {
      spinner: {
        display: 'block',
        background: 'good'
      }
    },
    ended: {
      inner: {
        background: 'good'
      }
    },
    failed: {
      inner: {
        background: 'bad'
      },
      icon: {
        transform: 'scale(0.8)'
      }
    },
    stopped: {
      inner: {
        background: 'faded'
      }
    },
    new: {
      inner: {
        background: 'primary'
      }
    },
    unknown: (props: StyleFunctionProps) => ({
      inner: {
        background: props.colorMode === 'dark' ? 'gray.600' : 'gray.300'
      }
    }),
    preparing: {
      spinner: {
        display: 'block',
        background: 'faded'
      }
    },
    deleting: {
      outer: {
        borderColor: 'faded'
      },
      spinner: {
        display: 'block',
        background: 'faded'
      }
    }
  },
  defaultProps: {
    size: 'md',
    variant: 'running'
  }
}
