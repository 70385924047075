import { useEffect } from 'react'
import { Link as WouterLink } from 'wouter'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Heading } from '@chakra-ui/react'

import { getProjectBuildsPageTitle } from '../../titles'

import { ProjectBuildsList } from '../project-builds/ProjectBuildsList'

import { Page } from '@/components/Page'
import { TruncatedTextWithTooltip } from '@/components/TruncatedTextWithTooltip'

type Props = {
  workspaceSlug: string
  projectSlug: string
}

export function ProjectBuildsPage(props: Props) {
  const { workspaceSlug, projectSlug } = props

  useEffect(() => {
    document.title = getProjectBuildsPageTitle(workspaceSlug, projectSlug)
  }, [workspaceSlug, projectSlug])

  return (
    <Page
      header={
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
          <BreadcrumbItem>
            <WouterLink to={`/${workspaceSlug}/${projectSlug}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={projectSlug} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Builds</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Heading mb={8}>Builds</Heading>
      <ProjectBuildsList workspaceSlug={workspaceSlug} projectSlug={projectSlug} />
    </Page>
  )
}
