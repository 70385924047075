import { useMemo } from 'react'
import { Card, SimpleGrid, SkeletonText, Stat, StatLabel } from '@chakra-ui/react'

import TooltippedStatNumber from '../../components/TooltippedStatNumber'
import { useGetDefectTrendQuery } from '../../redux/api/defects'
import { useGetAccountStatsQuery } from '../../redux/api/workspace'
import { DefectsBySeverityStack } from '../defect-tag/DefectsBySeverityStack'

type Props = { workspaceSlug: string }

export function WorkspaceStats({ workspaceSlug }: Props) {
  const timestamp = useMemo(() => {
    const timestampInSeconds = Math.floor(new Date().getTime() / 1000)
    return timestampInSeconds - (timestampInSeconds % 15)
  }, [])

  const { isFetching: isAccountStatsFetching, data: accountStats } = useGetAccountStatsQuery({
    owner: workspaceSlug
  })
  const { isLoading: isDefectsStatsFetching, data: trend } = useGetDefectTrendQuery({
    owner: workspaceSlug,
    windowStartTimestampSeconds: timestamp,
    windowEndTimestampSeconds: timestamp,
    // step shouldn't matter here, we are trying to get exactly 1 point
    windowStepSeconds: 1
  })
  const defectStats = (trend?.trend || [])[0]

  return (
    <SimpleGrid columns={{ base: 1, sm: 2 }} gap={6}>
      <Card p={8}>
        <SkeletonText isLoaded={!isDefectsStatsFetching} noOfLines={4} skeletonHeight={2} spacing={4}>
          <Stat border={0}>
            <StatLabel>Fixed Defects</StatLabel>
            {defectStats && (
              <DefectsBySeverityStack high={defectStats.fixed_high || 0} medium={defectStats.fixed_medium || 0} low={defectStats.fixed_low || 0} />
            )}
          </Stat>
        </SkeletonText>
      </Card>
      <Card p={8}>
        <SkeletonText isLoaded={!isDefectsStatsFetching} noOfLines={4} skeletonHeight={2} spacing={4}>
          <Stat border={0}>
            <StatLabel>Remaining Defects</StatLabel>
            {defectStats && (
              <DefectsBySeverityStack high={defectStats.active_high || 0} medium={defectStats.active_medium || 0} low={defectStats.active_low || 0} />
            )}
          </Stat>
        </SkeletonText>
      </Card>
      <Card p={8}>
        <SkeletonText isLoaded={!isAccountStatsFetching} noOfLines={3} skeletonHeight={2} spacing={4}>
          <Stat border={0}>
            <StatLabel>Projects</StatLabel>
            <TooltippedStatNumber value={accountStats?.project_count} />
          </Stat>
        </SkeletonText>
      </Card>
      <Card p={8}>
        <SkeletonText isLoaded={!isAccountStatsFetching} noOfLines={3} skeletonHeight={2} spacing={4}>
          <Stat border={0}>
            <StatLabel>Targets</StatLabel>
            <TooltippedStatNumber value={accountStats?.target_count} />
          </Stat>
        </SkeletonText>
      </Card>
    </SimpleGrid>
  )
}
