import { HStack, GridItem } from '@chakra-ui/react'

import { useGetRunQuery } from '../../redux/api/runs'

import { RunOptionsButton } from '../run-code/RunOptionsButton'

import { RunStatusSpinner } from './RunStatusSpinner'
import { RunStatusText } from './RunStatusText'

export interface RunStatusProps {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
}

export function RunStatus({ workspaceSlug, projectSlug, targetSlug, runNumber }: RunStatusProps) {
  const { isLoading, data: run } = useGetRunQuery({
    owner: workspaceSlug,
    projectSlug,
    targetSlug,
    runNumber
  })

  if (isLoading || !run) {
    return <></>
  }

  const { ended } = run || {}

  return (
    <GridItem gridArea="status" display="flex" alignSelf="start" justifyContent="right" marginLeft={8}>
      <HStack spacing={4}>
        <RunStatusText workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />
        <RunStatusSpinner indicatorSize="md" workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />
        {ended !== undefined && !ended && (
          <RunOptionsButton workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} runNumber={runNumber} />
        )}
      </HStack>
    </GridItem>
  )
}
