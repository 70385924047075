import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Center, HStack, Icon, Text, VStack } from '@chakra-ui/react'

import FileUploadIcon from '@material-design-icons/svg/outlined/upload_file.svg?react'

import { useDropzone } from 'react-dropzone'
import { useCallback, useState } from 'react'

import { useLocation } from 'wouter'

import { formatMemory } from '../../util/numbers'

import { useCreateDerivedReportMutation, usePostSourceReportMutation } from '../../redux/api/mdsbomApi'

import { setQueryParam } from '../../util/location'

import { useQuery } from '../../hooks'

import { generateErrorDescription } from './errors'
import { DsbomComparisonFileRow } from './DsbomComparisonFileRow'
import { DsbomComparisonSpinner } from './DsbomComparisonSpinner'
import { decodeUniqueImageReferenceKey } from './utils'

export const FILE_LIMIT = 10 * 1024 * 1024

interface DsbomComparisonFileSectionProps {
  onNext: () => void
  onBack: () => void
  workspaceSlug: string
}

type FileWithDsbomMetadata = {
  file: File
  isSca?: boolean
  isSbom?: boolean
  sourceReportId?: string
  hasError?: boolean
  isLoading?: boolean
}

export function DsbomComparisonFileSection({ onNext, onBack, workspaceSlug }: DsbomComparisonFileSectionProps) {
  const [files, setFiles] = useState<FileWithDsbomMetadata[]>([])
  const [derivedReportError, setDerivedReportError] = useState<boolean>(false)

  const [location, setLocation] = useLocation()
  const queryParams = useQuery()

  const [postSourceReport] = usePostSourceReportMutation()
  const [createDerivedReport, { isLoading: isLoadingDerivedReport }] = useCreateDerivedReportMutation()
  const [waitingForSync, setWaitingForSync] = useState(false)

  const encodedImageReference = queryParams.get('selectedImageReference')
  const decodedImageReference = encodedImageReference ? decodeUniqueImageReferenceKey(encodedImageReference) : ''
  const sbomReportId = queryParams.get('sbomReportId')
  const scaReportId = queryParams.get('scaReportId')

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      // Clear existing report params
      queryParams.delete('sbomReportId')
      queryParams.delete('scaReportId')
      setLocation(setQueryParam({ location, queryParams, param: `sbomReportId`, value: '' }))
      setLocation(setQueryParam({ location, queryParams, param: `scaReportId`, value: '' }))

      const uploadedFiles: FileWithDsbomMetadata[] = []
      const initialFiles = acceptedFiles.map((file) => ({
        file,
        isLoading: true
      }))
      setFiles(initialFiles)

      for (const file of acceptedFiles) {
        const payload = await postSourceReport({ file: file, imageReference: decodedImageReference, workspaceSlug }).unwrap()
        if (payload.errors) {
          uploadedFiles.push({
            file: file,
            hasError: true,
            isLoading: false
          })
          continue
        }
        const isSca = payload.data.createSourceReport.sourceReport.isSca
        const isSbom = payload.data.createSourceReport.sourceReport.isSbom
        const sourceReportId = payload.data.createSourceReport.sourceReport.id
        uploadedFiles.push({
          file: file,
          isSca: isSca,
          isSbom: isSbom,
          sourceReportId: sourceReportId,
          isLoading: false
        })
      }
      setFiles(uploadedFiles)
      // Set the sbom report id if the file is an SBOM
      // In case there exists a file that is only SCA we use that one for the scaReportId
      // We sort the files so that the SBOM is always first
      const sortedFiles = uploadedFiles.sort((a, _b) => (a.isSca ? -1 : 1))
      for (const file of sortedFiles) {
        if (file.isSbom) {
          setLocation(setQueryParam({ location, queryParams, param: `sbomReportId`, value: file.sourceReportId }))
          // Set the query param for the sbom report id, since the hook is not triggered and the next call wipes
          // the changes
          queryParams.set('sbomReportId', file.sourceReportId || '')
        }
        if (file.isSca) {
          setLocation(setQueryParam({ location, queryParams, param: `scaReportId`, value: file.sourceReportId }))
          queryParams.set('scaReportId', file.sourceReportId || '')
        }
      }
    },
    [postSourceReport, queryParams, location, setLocation, decodedImageReference, workspaceSlug]
  )

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 2,
    maxSize: FILE_LIMIT
  })

  // We accept files with a maximum size of 10MB and a maximum of 2 files
  const atLeastOneFile = files.length > 0
  const allFilesUploaded = files.every((file) => !file.isLoading)
  const hasBothScaAndSbom = sbomReportId && scaReportId

  const handleGenerateReport = useCallback(async () => {
    if (sbomReportId && scaReportId) {
      const derivedReport = await createDerivedReport({ sbomId: sbomReportId, scaId: scaReportId, workspaceSlug }).unwrap()

      const derivedReportSetId =
        derivedReport.data.createDerivedReports.scaDerivedReport?.derivedReportSet?.id ??
        derivedReport.data.createDerivedReports.sbomDerivedReport?.derivedReportSet?.id

      if (derivedReport.errors || !derivedReportSetId) {
        setDerivedReportError(true)
        return
      }

      setWaitingForSync(true)
      setTimeout(() => {
        setWaitingForSync(false)
        setLocation(
          setQueryParam({
            location,
            queryParams,
            param: 'derivedReportSetId',
            value: derivedReportSetId
          })
        )

        onNext()
      }, 3000)
    }
  }, [createDerivedReport, sbomReportId, scaReportId, onNext, setLocation, location, queryParams, workspaceSlug, setDerivedReportError])

  const errors = fileRejections
    .map((fileRejection) => {
      return fileRejection.errors.map((error) => {
        const errorDetails = generateErrorDescription(error.code, fileRejection.file.name, formatMemory(FILE_LIMIT))
        return (
          <Alert key={fileRejection.file.name} status="error" borderRadius="lg">
            <AlertIcon />
            <VStack justifyContent="flex-start" alignItems="flex-start">
              <AlertTitle textAlign="start" justifyContent="flex-start">
                {errorDetails.title}
              </AlertTitle>
              <AlertDescription>{errorDetails.description}</AlertDescription>
            </VStack>
          </Alert>
        )
      })
    })
    .flat()

  return (
    <VStack width="100%">
      {!isLoadingDerivedReport && !waitingForSync && (
        <>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            border="dashed"
            width="100%"
            height="64"
            borderRadius="15px"
            borderColor="chakra-border-color"
            borderWidth={isDragActive ? '4px' : '2px'}
            gap="2"
            marginBottom="4"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <Center w="100">
              <Icon as={FileUploadIcon} color="primary" boxSize="12" backgroundColor="blue.50" borderRadius="50%" padding="3" />
            </Center>
            <Box>
              <Text textAlign="center" fontWeight="semibold" fontSize="12px">
                Click anywhere here to upload your files, or drag and drop
              </Text>
            </Box>
            <Box>
              <Text textAlign="center" textColor="faded" fontSize="12px">
                Maximum: 10 MB, 2 Files
              </Text>
            </Box>
          </Box>
          <VStack width="100%">
            {files.map((file, index) => {
              if (file.hasError) {
                return (
                  <Alert key={index} status="error" borderRadius="lg">
                    <AlertIcon />
                    <VStack justifyContent="flex-start" alignItems="flex-start">
                      <AlertTitle textAlign="start" justifyContent="flex-start">
                        Error uploading {file.file.name}
                      </AlertTitle>
                      <AlertDescription>There was an error uploading the file. Please try again.</AlertDescription>
                    </VStack>
                  </Alert>
                )
              }
              return (
                <DsbomComparisonFileRow key={file.file.name} file={file.file} isLoading={!!file.isLoading} isSca={file.isSca} isSbom={file.isSbom} />
              )
            })}
            {derivedReportError && (
              <Alert status="error" borderRadius="lg">
                <AlertIcon />
                <VStack justifyContent="flex-start" alignItems="flex-start">
                  <AlertTitle textAlign="start" justifyContent="flex-start">
                    Error generating report
                  </AlertTitle>
                  <AlertDescription>There was an error generating your DSBOM report. Please refresh the page and try again.</AlertDescription>
                </VStack>
              </Alert>
            )}
            {files.length > 0 && allFilesUploaded && !hasBothScaAndSbom && (
              <Alert status="warning" borderRadius="lg">
                <AlertIcon />
                <VStack justifyContent="flex-start" alignItems="flex-start">
                  <AlertTitle textAlign="start" justifyContent="flex-start">
                    Missing SCA or SBOM
                  </AlertTitle>
                  <AlertDescription>Please upload at least one SCA and one SBOM file to proceed.</AlertDescription>
                </VStack>
              </Alert>
            )}
          </VStack>
          <VStack width="100%">{errors && errors.map((error) => error)}</VStack>
          <HStack>
            <Button onClick={onBack} variant="outline" paddingX={16}>
              Back
            </Button>
            {
              // Only enable the Next button if at least one file is uploaded and all files are uploaded
              atLeastOneFile && allFilesUploaded && hasBothScaAndSbom && files.every((v) => !v.hasError) && (
                <Button onClick={handleGenerateReport} isDisabled={!atLeastOneFile || !allFilesUploaded} paddingX={16}>
                  Next
                </Button>
              )
            }
          </HStack>
        </>
      )}
      {(isLoadingDerivedReport || waitingForSync) && <DsbomComparisonSpinner />}
    </VStack>
  )
}
