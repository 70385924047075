import DeleteAction from './DeleteAction'

import { useDeleteTeamMutation } from '@/redux/api/teams'

interface Props {
  onDelete?: () => void
  teamSlug: string
  workspaceSlug: string
  currentUserHasPermission: boolean
}

export default function TeamDeleteAction({ onDelete, teamSlug, workspaceSlug, currentUserHasPermission }: Props) {
  const [deleteTeam, { isLoading: isDeleting }] = useDeleteTeamMutation()

  return (
    <DeleteAction
      entity="Team"
      currentUserHasPermission={currentUserHasPermission}
      isDeleting={isDeleting}
      onDelete={onDelete}
      deleteAction={async () => {
        await deleteTeam({ owner: workspaceSlug, teamSlug }).unwrap()
      }}
    />
  )
}
