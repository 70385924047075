import { useCallback } from 'react'
import { Button, Card, CardBody, CardHeader, Flex, FormControl, FormLabel, Heading, Input, Skeleton, Spacer, Stack, useToast } from '@chakra-ui/react'

import { Controller, FormProvider, useForm } from 'react-hook-form'

import { WorkspaceDeleteButton } from './WorkspaceDeleteButton'

import { useGetAccountQuery, usePutOrgMutation, useWhoamiQuery } from '@/redux/api/workspace'
import { getMessageFromRtkError } from '@/util/errors'
import { WorkerPoolPicker } from '@/features/admin-workers/WorkerPoolPicker'

type Form = {
  name: string
  description: string
  workerPoolId: number | null
}

interface Props {
  workspaceSlug: string
  allowWrite: boolean
}

export function GeneralCard({ workspaceSlug, allowWrite }: Props) {
  const { data: profile } = useWhoamiQuery()
  const { isLoading, data, refetch: refetchWorkspace } = useGetAccountQuery({ owner: workspaceSlug })

  const defaultValues = {
    name: data?.orgname,
    description: data?.description ?? undefined,
    workerPoolId: data?.worker_pool_id
  }

  const methods = useForm<Form>({
    defaultValues
  })

  const toast = useToast()
  const [putWorkspace] = usePutOrgMutation()
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { isSubmitting, isDirty, errors }
  } = methods

  const onSubmit = useCallback(
    async (form: Form) => {
      try {
        await putWorkspace({
          orgSlug: workspaceSlug,
          orgPut: {
            orgname: form.name,
            description: form.description,
            worker_pool_id: form.workerPoolId
          }
        }).unwrap()
        await refetchWorkspace()
        reset({
          name: form.name,
          description: form.description,
          workerPoolId: form.workerPoolId
        })
        toast({
          title: 'Workspace Updated',
          status: 'success',
          duration: 5000,
          isClosable: true
        })
      } catch (err: unknown) {
        toast({
          title: 'Update Workspace Failed',
          description: getMessageFromRtkError(err),
          status: 'error',
          duration: 9000,
          isClosable: true
        })
      }
    },
    [toast, refetchWorkspace, reset, putWorkspace, workspaceSlug]
  )

  if (isLoading || data === undefined) {
    return <Skeleton />
  }

  return (
    <Card>
      <CardHeader justifyContent="center">
        <Heading variant="cardHeading">Workspace</Heading>
      </CardHeader>
      <CardBody>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={8} p={4}>
              <FormControl isInvalid={!!errors.root}>
                <FormLabel>Workspace Display Name</FormLabel>
                <Input disabled={!allowWrite} {...register('name')} />
              </FormControl>
              <FormControl isInvalid={!!errors.root}>
                <FormLabel>Description</FormLabel>
                <Input disabled={!allowWrite} {...register('description')} />
              </FormControl>
              {profile?.is_admin && (
                <FormControl isInvalid={!!errors.root}>
                  <FormLabel>Worker Pool</FormLabel>
                  <Controller
                    control={control}
                    name="workerPoolId"
                    render={({ field: { value, onChange } }) => (
                      <WorkerPoolPicker value={value} onChange={onChange} disabled={!profile || !profile.is_admin} />
                    )}
                  />
                </FormControl>
              )}
              <Flex gap={4}>
                {allowWrite && profile && profile.slug && <WorkspaceDeleteButton userSlug={profile?.slug} workspaceSlug={workspaceSlug} />}
                <Spacer />
                <Button
                  variant="outline"
                  isDisabled={isSubmitting || !isDirty}
                  onClick={(): void => {
                    reset()
                  }}
                >
                  Cancel
                </Button>
                <Button variant="solid" type="submit" isDisabled={isSubmitting || !isDirty} isLoading={isSubmitting}>
                  Update
                </Button>
              </Flex>
            </Stack>
          </form>
        </FormProvider>
      </CardBody>
    </Card>
  )
}
