import { useEffect } from 'react'

import { Link as WouterLink, useLocation } from 'wouter'
import {
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Stack,
  HStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Divider,
  Card,
  Container,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text
} from '@chakra-ui/react'

import MayhemTableLoader from '../../components/MayhemTableLoader'
import { Page } from '../../components/Page'
import { TruncatedTextWithTooltip } from '../../components/TruncatedTextWithTooltip'
import { useQuery } from '../../hooks'
import { useGetTestcaseReportByRunAndChecksumQuery } from '../../redux/api/defects'
import { useGetRunQuery } from '../../redux/api/runs'
import { getTestCaseReportPageTitle } from '../../titles'
import { setQueryParam } from '../../util/location'
import { prettyTime } from '../../util/time'

import StepsToReproduce from '../run-code/StepsToReproduce'
import TestcaseShaDisplay from '../run-code/TestcaseShaDisplay'

import { TestCaseRelatedDefectsReport } from './TestCaseRelatedDefectsReport'
import TestCaseAdvancedTriageReport from './TestCaseAdvancedTriageReport'
import TestCaseTriageReport from './TestCaseTriageReport'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  testcaseSha: string
  defectNumber?: string
  fromRunNumber?: number
}

type Tabs = 'related-defects' | 'triage' | 'advanced-triage'

const tabs: Tabs[] = ['related-defects', 'triage', 'advanced-triage']

export function TestCaseReportPage({ workspaceSlug, projectSlug, targetSlug, runNumber, testcaseSha }: Props) {
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()
  const testcaseTab = queryParams.get('testcaseTab')

  useEffect(() => {
    document.title = getTestCaseReportPageTitle(workspaceSlug, projectSlug, targetSlug, runNumber.toString(), testcaseSha)
  }, [workspaceSlug, projectSlug, targetSlug, runNumber, testcaseSha])

  const { data: runData } = useGetRunQuery({ owner: workspaceSlug, projectSlug, targetSlug, runNumber })
  const { data: testCaseReportData, isFetching } = useGetTestcaseReportByRunAndChecksumQuery({
    owner: workspaceSlug,
    projectSlug,
    targetSlug,
    runNumber,
    sha256: testcaseSha
  })

  const startedAt = runData?.started_at ? new Date(runData?.started_at).getTime() : null
  const {
    defects = [],
    valgrind_errors: valgrindErrors,
    disassembly,
    output,
    registers,
    repro_cmdline: reproCmdline,
    backtrace,
    signal_number: signalNumber,
    faulting_address: faultingAddress,
    maps,
    asan_output: asanOutput,
    sha256,
    n_valgrind_errors: nValgrindErrors,
    hexdump,
    execution_time: executionTime,
    timed_out: timedOut
  } = testCaseReportData || {}

  const downloadFileName = testCaseReportData?.sha256
  let displayExecutionTime: JSX.Element | string = !startedAt || Number.isNaN(executionTime) || !executionTime ? '' : prettyTime(executionTime)
  if (displayExecutionTime !== '') {
    const timeoutLabel = <Text color="bad">timeout</Text>
    displayExecutionTime = timedOut ? (
      <>
        {timeoutLabel} <br /> ({displayExecutionTime})
      </>
    ) : (
      displayExecutionTime
    )
  }

  const handleTabChange = (index: number): void => {
    const newTab = tabs.at(index)
    const url = setQueryParam({ location, queryParams, param: 'testcaseTab', value: newTab || '' })
    setLocation(url, { replace: true })
  }

  const isLoaded = !isFetching

  let activeTabIndexOnMount = tabs.findIndex((tab) => tab === testcaseTab)
  if (activeTabIndexOnMount === -1) {
    activeTabIndexOnMount = 0
  }

  return (
    <Page
      header={
        <Breadcrumb sx={{ ol: { flexWrap: 'wrap' } }}>
          <BreadcrumbItem>
            <WouterLink to={`/${workspaceSlug}/${projectSlug}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={projectSlug} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <WouterLink to={`/${workspaceSlug}/${projectSlug}/${targetSlug}`}>
              <BreadcrumbLink>
                <TruncatedTextWithTooltip text={targetSlug} />
              </BreadcrumbLink>
            </WouterLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={WouterLink} to={`/${workspaceSlug}/${projectSlug}/${targetSlug}/${runNumber}`}>
              Run {runNumber}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>Test Case {testcaseSha.substring(0, 8)}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      }
    >
      <Stack gap={4}>
        <HStack spacing={4} alignItems="baseline">
          <Heading>Test Case</Heading>
          <TestcaseShaDisplay sha256={testcaseSha} showCopyButton={false} fontSize={32} />
        </HStack>
        <Divider />
        <StatGroup gap={4} justifyContent="start">
          <Card p={4}>
            <Stat border={0}>
              <StatLabel>Defects</StatLabel>
              <StatNumber>{defects.length}</StatNumber>
            </Stat>
          </Card>
          <Card p={4}>
            <Stat border={0}>
              <StatLabel>Runtime Errors</StatLabel>
              <StatNumber>{nValgrindErrors}</StatNumber>
            </Stat>
          </Card>
          <Card p={4}>
            <Stat border={0}>
              <StatLabel>Execution Time</StatLabel>
              <StatNumber>{displayExecutionTime}</StatNumber>
            </Stat>
          </Card>
        </StatGroup>
        <Card p={4}>
          <StepsToReproduce
            workspaceSlug={workspaceSlug}
            projectSlug={projectSlug}
            targetSlug={targetSlug}
            sha256={sha256}
            reproCmdline={reproCmdline}
            downloadFileName={downloadFileName}
          />
        </Card>

        <Container width="full" maxW="full" padding={0}>
          <Tabs variant="line" isLazy width="full" defaultIndex={activeTabIndexOnMount} onChange={handleTabChange}>
            <TabList>
              <Tab>{`Defects (${defects.length})`}</Tab>
              <Tab>Triage</Tab>
              <Tab>{`Advanced Triage ${nValgrindErrors ? `(${nValgrindErrors})` : ''}`}</Tab>
            </TabList>
            <TabPanels paddingTop={4}>
              <TabPanel paddingX={0}>
                {!isLoaded && <MayhemTableLoader />}
                {isLoaded && (
                  <TestCaseRelatedDefectsReport workspaceSlug={workspaceSlug} projectSlug={projectSlug} targetSlug={targetSlug} defects={defects} />
                )}
              </TabPanel>
              <TabPanel paddingX={0}>
                {!isLoaded && <MayhemTableLoader />}
                {isLoaded && (
                  <Card p={4}>
                    <TestCaseTriageReport
                      disassembly={disassembly}
                      hexdump={hexdump}
                      output={output}
                      registers={registers}
                      backtrace={backtrace}
                      asanOutput={asanOutput}
                      faultingAddress={faultingAddress}
                      signalNumber={signalNumber}
                      maps={maps}
                    />
                  </Card>
                )}
              </TabPanel>
              <TabPanel paddingX={0}>
                {!isLoaded && <MayhemTableLoader />}
                {isLoaded && (
                  <Card p={4}>
                    <TestCaseAdvancedTriageReport
                      workspaceSlug={workspaceSlug}
                      projectSlug={projectSlug}
                      targetSlug={targetSlug}
                      valgrindErrors={valgrindErrors}
                    />
                  </Card>
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Container>
      </Stack>
    </Page>
  )
}
