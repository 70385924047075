import { Table, TableContainer, Tbody } from '@chakra-ui/react'

import { WorkspaceInviteListItem } from './WorkspaceInviteListItem'

import { DEFAULT_PER_PAGE } from '@/redux/api/mayhemApi'
import { useGetAccountInvitesQuery } from '@/redux/api/invites'

import { MayhemPagination } from '@/components/MayhemPagination'

import { cleanInteger } from '@/util/numbers'

import { useQuery } from '@/hooks'

import ListDefaults from '@/components/ListDefaults'

import { TableCard } from '@/components/Card/TableCard'

interface WorkspaceInviteListProps {
  workspaceSlug: string
}

const paginationPrefix = 'invites-'

export function WorkspaceInviteList({ workspaceSlug }: WorkspaceInviteListProps) {
  const query = useQuery()
  const page = cleanInteger(query.get(`${paginationPrefix}page`), 1)
  const perPage = cleanInteger(query.get(`${paginationPrefix}perPage`), DEFAULT_PER_PAGE)

  const { currentData: invitesData, isFetching, isError } = useGetAccountInvitesQuery({ owner: workspaceSlug, perPage, offset: (page - 1) * perPage })

  const invitations = invitesData?.invitations.map((invitation) => (
    <WorkspaceInviteListItem key={invitation.account_invitation_id} workspaceSlug={workspaceSlug} {...invitation} />
  ))
  return (
    invitations &&
    invitations?.length > 0 && (
      <TableCard title="Pending User Invitations" CardBodyProps={{ paddingTop: 0 }}>
        {!isFetching && invitations?.length > 0 && (
          <TableContainer>
            <Table>
              <Tbody>{invitations}</Tbody>
            </Table>
          </TableContainer>
        )}
        <ListDefaults isLoading={isFetching} nItems={invitations?.length} itemName="invitation" isFail={isError} />
        <MayhemPagination isLoading={isFetching} total={invitesData?.count || 0} prefix={paginationPrefix} />
      </TableCard>
    )
  )
}
