import { ReactNode } from 'react'
import { Alert, AlertIcon, Stack, Text } from '@chakra-ui/react'
import { skipToken } from '@reduxjs/toolkit/query'

import OutsideLink from '../../components/OutsideLink'

import { useGetProjectMemberPermissionQuery } from '../../redux/api/projects'
import { useAppSelector } from '../../hooks'
import { useWhoamiQuery } from '../../redux/api/workspace'
import { ProjectAccessLevelType } from '../../types'
import MayhemTableLoader from '../../components/MayhemTableLoader'

export function ProjectPermissionGuardText(): JSX.Element {
  const isPublicDeployment = useAppSelector((state) => state.config.isPublicDeployment)
  if (isPublicDeployment) {
    return (
      <Stack spacing={4}>
        <Text>Test cases and defect findings are sensitive and you need to have read access on the project to view them.</Text>
        <Text>
          Given that the project is open source we believe that any developer should be able to check the security of the software they are using /
          developing against.
        </Text>
        <Text>
          You can get a similar run with Mayhem by cloning the repository on Github and starting the Mayhem action against it. See more about how it
          works <OutsideLink href="https://docs.mayhem.security/integrations/ci-integrations/github/">here</OutsideLink>.
        </Text>
      </Stack>
    )
  }
  return (
    <Text>
      Insufficient permissions to see details about test cases and defect findings. You must be a member of the organization. If this is an error
      please contact the owner.
    </Text>
  )
}

export interface ProjectPermissionGuardProps {
  children: ReactNode
  workspaceSlug: string
  projectSlug: string
}

export const ProjectPermissionGuard = ({ workspaceSlug, projectSlug, children }: ProjectPermissionGuardProps) => {
  const { isLoading: isLoadingWhoami, data: currentUser } = useWhoamiQuery()
  const {
    isLoading,
    data: permission,
    isError
  } = useGetProjectMemberPermissionQuery(
    isLoadingWhoami || !currentUser
      ? skipToken
      : {
          owner: workspaceSlug,
          projectSlug,
          userSlug: currentUser?.slug as string
        }
  )

  if (isLoadingWhoami || isLoading) {
    return <MayhemTableLoader />
  }

  if (!isError && (permission?.permission as ProjectAccessLevelType) !== 'MEMBER') {
    return <>{children}</>
  }
  return (
    <Alert status="warning">
      <AlertIcon />
      <ProjectPermissionGuardText />
    </Alert>
  )
}

export default ProjectPermissionGuard
