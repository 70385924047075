import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'

import { useLocation } from 'wouter'

import { useState } from 'react'

import { useQuery } from '../../hooks'
import { setQueryParams } from '../../util/location'

import { DefectPreview } from './DefectPreview'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  defectNumber: number
  runNumber?: number
}

export function DefectModal({ workspaceSlug, projectSlug, targetSlug, defectNumber, runNumber }: Props) {
  const [location, setLocation] = useLocation()
  const queryParams = useQuery()

  const [parentPageTitle] = useState(document.title)

  const goBackSafely = (): void => {
    const newUrl = setQueryParams({
      location,
      queryParams: queryParams,
      params: [
        { param: 'defect', value: null },
        { param: 'exampleTestcase', value: null },
        { param: 'defectTarget', value: null },
        { param: 'defectLastRunNumber', value: null }
      ]
    })
    setLocation(newUrl, { replace: true })
  }

  return (
    <Modal
      isCentered={false}
      size="5xl"
      scrollBehavior="inside"
      isOpen
      onClose={() => {
        document.title = parentPageTitle
        goBackSafely()
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <DefectPreview {...{ workspaceSlug, projectSlug, targetSlug, defectNumber, runNumber }} isModal />
      </ModalContent>
    </Modal>
  )
}
