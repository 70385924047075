import { useEffect } from 'react'

import { getWorkspacePageTitle } from '../../titles'

import { WebhooksCard } from './WebhooksCard'

interface Props {
  workspaceSlug: string
}

export function WebhooksTab({ workspaceSlug }: Props) {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspaceSlug, 'Webhooks')
  }, [workspaceSlug])

  return <WebhooksCard workspaceSlug={workspaceSlug} />
}
