import { dump, load } from 'js-yaml'

import { Opaque, opaque } from '../../opaque'
import { areSetEqual, skipIfDefault, pruneUndefined, slugifyFull, pruneIf } from '../../utils'
import { cleanInteger } from '../../util/numbers'
import { CoreCodeRunCommand, CoreCodeRunConfig } from '../run-code-config/CoreCodeRunConfig'
import { parseToIntIfString } from '../../util/parseIntSafe'

/**
 * The schema of serialized mayhemfiles for code runs.
 */
export type Mayhemfile = Opaque<
  {
    version?: string
    project: string
    target: string
    image?: string
    duration?: number | string
    advanced_triage?: boolean
    uid?: number
    gid?: number
    tasks?: MayhemfileTask[]
    testsuite?: string[]
    cmds: MayhemfileCommand[]
  },
  'mayhemfile'
>

export interface MayhemfileTask {
  name: 'exploitability_factors' | 'behavior_testing' | 'regression_testing' | 'coverage_analysis'
}

export interface MayhemfileCommand {
  cmd: string
  env?: Record<string, string>
  network?: {
    url?: string
    timeout?: number
    client?: boolean
  }
  honggfuzz?: boolean | undefined
  libfuzzer?: boolean | undefined
  disable_mayhem_fuzz?: boolean
  disable_se?: boolean
  sanitizer?: boolean | undefined
  afl?: boolean | undefined
  max_length?: number
  cwd?: string
  filepath?: string
  memory_limit?: number
  dictionary?: string
  timeout?: number
}

export interface MayhemfileContext {
  workspaceSlug: string
}

const mayhemfileDefaultTasks: MayhemfileTask['name'][] = [
  // format: no-inline
  'exploitability_factors',
  'behavior_testing',
  'regression_testing'
]

const mayhemfileDefaultTestsuite = [
  // format: no-inline
  'https://$MAYHEM_DOMAIN/$MAYHEM_PROJECT/$MAYHEM_TARGET/testsuite.tar',
  'file://testsuite'
]

function prune<T extends Record<string, unknown>>(value: T): T
function prune<T extends Record<string, unknown>>(value: T | undefined): T | undefined
function prune<T extends Record<string, unknown>>(value: T | undefined): T | undefined {
  if (value === undefined) {
    return undefined
  }
  return pruneIf(value, (key, value) => {
    if (value === undefined) {
      return true
    }
    if (typeof value === 'string') {
      return value.length === 0
    }
    if (typeof value === 'object') {
      return !value || Object.keys(value).length === 0
    }
    return false
  })
}

export const toMayhemfile = (config: CoreCodeRunConfig): Mayhemfile => {
  const mapNetwork = (network: CoreCodeRunCommand['network']) => {
    if (network === undefined) {
      return undefined
    }
    return prune({
      url: network.url,
      timeout: parseToIntIfString(network.timeout),
      client: skipIfDefault(network.client, false)
    })
  }

  const mapCommand = (command: CoreCodeRunCommand) => {
    return prune({
      cmd: command.command,
      env: command.environment,
      network: mapNetwork(command.network),
      honggfuzz: command.honggfuzz,
      libfuzzer: command.libfuzzer,
      disable_mayhem_fuzz: command.mayhemFuzz !== undefined ? !command.mayhemFuzz : undefined,
      disable_se: command.mayhemSe !== undefined ? !command.mayhemSe : undefined,
      sanitizer: command.sanitizer,
      afl: command.afl,
      max_length: parseToIntIfString(command.maxLength),
      cwd: command.cwd,
      filepath: command.filepath,
      memory_limit: parseToIntIfString(command.memoryLimit),
      dictionary: command.dictionary,
      timeout: parseToIntIfString(command.timeout)
    })
  }
  const mayhemfile = opaque<Mayhemfile>(
    prune({
      project: `${slugifyFull(config.workspaceSlug)}/${slugifyFull(config.projectNameOrSlug)}`,
      target: slugifyFull(config.targetNameOrSlug),
      image: config.image,
      duration: skipIfDefault(parseToIntIfString(config.duration), 0),
      advanced_triage: skipIfDefault(config.advancedTriage, false),
      uid: parseToIntIfString(config.uid),
      gid: parseToIntIfString(config.gid),
      tasks: skipIfDefault(config.tasks, mayhemfileDefaultTasks, areSetEqual)
        // format: no-inline
        ?.map((task) => ({ name: task })),
      testsuite: skipIfDefault(config.testsuite, mayhemfileDefaultTestsuite, areSetEqual),
      cmds: config.commands.map(mapCommand)
    })
  )

  // Remove fields if deemed unnecessary
  return pruneUndefined(mayhemfile, [
    // format: no-inline
    'image',
    'duration',
    'tasks',
    'testsuite',
    'advanced_triage',
    'uid',
    'gid'
  ])
}

export const fromMayhemfile = (mayhemfile: Mayhemfile, { workspaceSlug }: MayhemfileContext): CoreCodeRunConfig => {
  const mapCommand = (command: MayhemfileCommand) => ({
    command: command.cmd,
    environment: command.env || {},
    network: command.network,
    honggfuzz: command.honggfuzz,
    libfuzzer: command.libfuzzer,
    mayhemFuzz: command.disable_mayhem_fuzz,
    mayhemSe: command.disable_se,
    sanitizer: command.sanitizer,
    afl: command.afl,
    maxLength: command.max_length,
    cwd: command.cwd,
    filepath: command.filepath,
    memoryLimit: command.memory_limit,
    dictionary: command.dictionary,
    timeout: command.timeout
  })
  const projectParts = mayhemfile.project.split('/')
  return opaque({
    version: mayhemfile.version,
    workspaceSlug: projectParts.length >= 2 ? projectParts[0] : workspaceSlug,
    projectNameOrSlug: projectParts[projectParts.length - 1],
    targetNameOrSlug: mayhemfile.target,
    image: mayhemfile.image,
    duration: mayhemfile.duration ? cleanInteger(mayhemfile.duration) : undefined,
    advancedTriage: mayhemfile.advanced_triage || false,
    uid: mayhemfile.uid,
    gid: mayhemfile.gid,
    tasks: mayhemfile.tasks?.map((task) => task.name) || [...mayhemfileDefaultTasks],
    testsuite: mayhemfile.testsuite || [...mayhemfileDefaultTestsuite],
    commands: mayhemfile.cmds.map(mapCommand),
    isFirstRun: false // never true in this context
  })
}

/**
 *
 * @param config
 */
export const mayhemfileYamlSerialize = (config: CoreCodeRunConfig): string => {
  return dump(toMayhemfile(config), { lineWidth: Number.MAX_SAFE_INTEGER })
}

export function mayhemfileYamlDeserialize(yaml: string, context: MayhemfileContext): CoreCodeRunConfig
/**
 * Parses a yaml string, and converts to a CodeMayhemfile.
 *
 * @param {string | undefined} yaml - The yaml
 * @param {MayhemfileContext} context - The workspace this mayhemfile belongs to.
 * @returns {CodeRunConfig | undefined} - The parse CodeMayhemfile.
 */
export function mayhemfileYamlDeserialize(yaml: string | undefined, context: MayhemfileContext): CoreCodeRunConfig | undefined {
  if (!yaml) {
    return undefined
  }
  return fromMayhemfile(load(yaml) as Mayhemfile, context)
}
