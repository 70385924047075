import { opaque, Opaque, PartialOpaque } from '../../opaque'
import { mayhemParseFloat, slugifyFull, zip } from '../../utils'

import { CodeRunConfigDuration, CoreCodeRunCommand, CoreCodeRunCommandNetwork, CoreCodeRunConfig, CoreCodeTask } from './CoreCodeRunConfig'

/**
 * Necessary details to create a run.
 */
export type FormCodeRunConfig = Opaque<
  {
    projectNameOrSlug: string // name when user-inputted, slug when read from Mayhemfile
    targetNameOrSlug: string // name when user-inputted, slug when read from Mayhemfile
    dockerImage: string
    cmds: string
    cmdSettings?: FormCodeRunCommand[]
    duration: CodeRunConfigDuration
    tasks: CoreCodeTask[]
    advanced_triage: boolean
    uid?: number
    gid?: number
  },
  'form:run-code-config'
>

export interface FormCodeRunCommand {
  env?: { key: string; value: string }[]
  network?: FormCodeRunCommandNetwork
  afl: 'automatic' | 'enabled' | 'disabled'
  honggfuzz: 'automatic' | 'enabled' | 'disabled'
  libfuzzer: 'automatic' | 'enabled' | 'disabled'
  sanitizer: 'automatic' | 'enabled' | 'disabled'
  max_length?: number
  cwd?: string
  filepath?: string
  memory_limit?: number
  mayhem_timeout?: number
  dictionary?: string
}

export interface FormCodeRunCommandNetwork {
  url?: string
  timeout?: string
  client?: boolean
}

/**
 * A type to hold information that are not present on the form
 *  but are part of a run config.
 */
export interface FormCodeRunConfigContext {
  workspaceSlug: string
  testsuite: string[]
  isFirstRun: boolean
}

export const toForm = (config: PartialOpaque<CoreCodeRunConfig> | undefined, defaultValues: FormCodeRunConfig): FormCodeRunConfig => {
  const mapNetwork = (network: CoreCodeRunCommandNetwork | undefined) => {
    if (network === undefined) {
      return undefined
    }
    return {
      url: network.url,
      timeout: network.timeout?.toString(),
      client: network.client
    }
  }
  const mapCommands = (commands: CoreCodeRunCommand[] | undefined) => {
    if (!commands) {
      return undefined
    }
    return commands.map((command) => `$ ${command.command}`).join('\n')
  }
  const mapSettings = (commands: CoreCodeRunCommand[] | undefined) => {
    if (!commands) {
      return undefined
    }
    return commands.map(
      (command): FormCodeRunCommand => ({
        env: Object.entries(command.environment).map(([key, value]) => ({ key, value })),
        network: mapNetwork(command.network),
        honggfuzz: formatFuzzerOption(command.honggfuzz),
        libfuzzer: formatFuzzerOption(command.libfuzzer),
        afl: formatFuzzerOption(command.afl),
        sanitizer: formatFuzzerOption(command.sanitizer),
        max_length: command.maxLength,
        cwd: command.cwd,
        filepath: command.filepath,
        memory_limit: command.memoryLimit,
        mayhem_timeout: command.timeout,
        dictionary: command.dictionary
      })
    )
  }

  const [projectNameOrSlug, projectStringTargetToken] = splitProjectName(config?.projectNameOrSlug || defaultValues.projectNameOrSlug)
  const targetNameOrSlug = config?.targetNameOrSlug || projectStringTargetToken || defaultValues.targetNameOrSlug

  return opaque({
    projectNameOrSlug,
    targetNameOrSlug,
    dockerImage: config?.image || defaultValues.dockerImage,
    duration: config?.duration || defaultValues.duration,
    tasks: config?.tasks || defaultValues.tasks,
    advanced_triage: config?.advancedTriage || defaultValues.advanced_triage,
    uid: config?.uid || defaultValues.uid,
    gid: config?.gid || defaultValues.gid,
    cmds: mapCommands(config?.commands) || defaultValues.cmds,
    cmdSettings: mapSettings(config?.commands) || defaultValues.cmdSettings
  })
}

export const fromForm = (formConfig: FormCodeRunConfig, context: FormCodeRunConfigContext): CoreCodeRunConfig => {
  const mapNetwork = (network: FormCodeRunCommandNetwork | undefined) => {
    if (network === undefined) {
      return undefined
    }
    return {
      url: network.url,
      timeout: mayhemParseFloat(network.timeout, undefined),
      client: network.client
    }
  }
  const mapCommands = (command: string, settings: FormCodeRunCommand[]): CoreCodeRunCommand[] => {
    const commands = parseCommands(command)

    return zip(commands, settings).map(([command, settings]) => ({
      // settings may be undefined if len(commands) > len(cmdSettings)
      command,
      environment: settings?.env?.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {} as Record<string, string>) || {},
      network: mapNetwork(settings?.network),
      honggfuzz: parseFuzzerOption(settings?.honggfuzz || 'automatic'),
      libfuzzer: parseFuzzerOption(settings?.libfuzzer || 'automatic'),
      afl: parseFuzzerOption(settings?.afl || 'automatic'),
      sanitizer: parseFuzzerOption(settings?.sanitizer || 'automatic'),
      mayhemFuzz: undefined,
      mayhemSe: undefined,
      maxLength: settings?.max_length,
      cwd: settings?.cwd,
      filepath: settings?.filepath,
      memoryLimit: settings?.memory_limit,
      timeout: settings?.mayhem_timeout,
      dictionary: settings?.dictionary
    }))
  }
  const [projectSlug, projectTargetToken] = parseProjectName(formConfig.projectNameOrSlug)
  const targetSlug = formConfig?.targetNameOrSlug || projectTargetToken
  return opaque({
    workspaceSlug: context.workspaceSlug,
    projectNameOrSlug: projectSlug,
    targetNameOrSlug: targetSlug || projectSlug,
    image: formConfig.dockerImage,
    duration: formConfig.duration,
    testsuite: context.testsuite,
    advancedTriage: formConfig.advanced_triage,
    uid: formConfig.uid,
    gid: formConfig.gid,
    tasks: formConfig.tasks,
    commands: mapCommands(formConfig.cmds, formConfig.cmdSettings || []),
    isFirstRun: context.isFirstRun
  })
}

export const displayCommands = (command: string) => {
  return (
    command
      .split('\n')
      // remove lines which a user tries to backspace away
      .filter((str) => str !== '$')
      // remove the prefix if it exists
      .map((str) => str.replace('$', ''))
      // left trim regex
      .map((str) => str.replace(/^\s+/, ''))
      // add it back (this removing/adding process will add '$ ' to lines which did not have it previously)
      .map((str) => `$ ${str}`)
      .join('\n')
  )
}

export const parseCommands = (cmds: string) => {
  return (
    cmds
      .split('\n')
      // remove lines which a user tries to backspace away
      .filter((str) => str !== '$')
      // remove the prefix if it exists
      .map((str) => str.replace('$', ''))
      // left trim regex
      .map((str) => str.replace(/^\s+/, ''))
  )
}

const formatFuzzerOption = (option: boolean | undefined): 'automatic' | 'enabled' | 'disabled' => {
  if (option === true) {
    return 'enabled'
  }
  if (option === false) {
    return 'disabled'
  }
  return 'automatic'
}
const parseFuzzerOption = (option: 'automatic' | 'enabled' | 'disabled'): boolean | undefined => {
  switch (option) {
    case 'enabled':
      return true
    case 'disabled':
      return false
    case 'automatic':
    default:
      return undefined
  }
}

export const splitProjectName = (name: string): [string, string | undefined] => {
  const result: (string | undefined)[] = (name || '').split('/').slice(0, 2)
  if (result.length === 1) {
    result.push(undefined)
  }
  const [project, target] = result
  return [project || name, target]
}

export const parseProjectName = (name: string): [string] | [string, string] => {
  if (!name?.includes('/')) {
    return [slugifyFull(name)]
  }
  const [project, target] = name.split('/')
  return [slugifyFull(project), slugifyFull(target)]
}
