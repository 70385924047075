import { useEffect } from 'react'

import { Page } from '../../components/Page'
import { CreateAPIProjectContainer } from '../run-api-create/CreateAPIProject'
import { getWorkspacePageTitle } from '../../titles'
import { useAppSelector } from '../../hooks'

import { MapiSpecUrlExample, MapiUrlExample, selectNewApiRun } from './create-api-run.slice'

export const CreateApiRunPage = ({ workspaceSlug }: CreateApiRunPageProps) => {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspaceSlug, 'New API project')
  }, [workspaceSlug])

  const { apiUrl = MapiUrlExample, specUrl = MapiSpecUrlExample } = useAppSelector(selectNewApiRun)
  return (
    <Page header="Add New API Project">
      <CreateAPIProjectContainer workspaceSlug={workspaceSlug} url={apiUrl} openApiSpec={specUrl} />
    </Page>
  )
}

export interface CreateApiRunPageProps {
  workspaceSlug: string
}
