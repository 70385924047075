import React, { useCallback } from 'react'
import { CardBody, useDisclosure } from '@chakra-ui/react'

import { ErrorPanel } from '../../components/ErrorPanel'
import ListDefaults from '../../components/ListDefaults'
import { MayhemPagination } from '../../components/MayhemPagination'
import { useQuery } from '../../hooks'
import { cleanInteger } from '../../util/numbers'
import { DEFAULT_PER_PAGE } from '../../redux/api/mayhemApi'
import { useGetWebhooksByAccountQuery, useGetWebhooksByProjectQuery, useGetWebhooksQuery } from '../../redux/api/webhooks'
import { useAppSelector } from '../../hooks'

import { WebhookRow } from './WebhookRow'
import { CreateWebhookModal } from './CreateWebhookModal'

interface Props {
  workspaceSlug?: string
  projectSlug?: string
}

export function WebhookList({ workspaceSlug, projectSlug }: Props) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const query = useQuery()
  const page = cleanInteger(query.get('page'), 1)
  const perPage = cleanInteger(query.get('perPage'), DEFAULT_PER_PAGE)

  const lastSelectedWebhook = useAppSelector((state) => state.webhooks.webhook)

  // APIs are separate by account / project - a single API would let us have a
  // single query
  const accountQueryDisabled = !workspaceSlug || projectSlug !== undefined
  const {
    webhooks: webhooksByAccount,
    isFetching: webhooksByAccountFetching,
    isError: webhooksByAccountError,
    count: webhooksByAccountCount,
    refetch: refetchWebhooksByAccount
  } = useGetWebhooksByAccountQuery({ owner: workspaceSlug || '', page, perPage }, { skip: accountQueryDisabled })

  const projectQueryDisabled = !workspaceSlug || projectSlug === undefined
  const {
    webhooks: webhooksByProject,
    isFetching: webhooksByProjectFetching,
    isError: webhooksByProjectError,
    count: webhooksByProjectCount,
    refetch: refetchWebhooksByProject
  } = useGetWebhooksByProjectQuery(
    {
      owner: workspaceSlug || '',
      projectSlug: projectSlug || '',
      page,
      perPage
    },
    { skip: projectQueryDisabled }
  )

  const globalQueryDisabled = !projectQueryDisabled || !accountQueryDisabled
  const {
    data: globalWebhooksData,
    isFetching: globalWebhooksFetching,
    isError: globalWebhooksError,
    refetch: refetchGlobalWebhooks
  } = useGetWebhooksQuery({ offset: (page - 1) * perPage, perPage }, { skip: globalQueryDisabled })
  const globalWebhooks = globalWebhooksData?.webhooks || []
  const globalWebhooksCount = globalWebhooksData?.count || 0

  const handleMutate = useCallback(() => {
    if (!projectQueryDisabled) {
      refetchWebhooksByProject()
    }
    if (!accountQueryDisabled) {
      refetchWebhooksByAccount()
    }
    if (!globalQueryDisabled) {
      refetchGlobalWebhooks()
    }
  }, [refetchWebhooksByProject, refetchWebhooksByAccount, refetchGlobalWebhooks, projectQueryDisabled, accountQueryDisabled, globalQueryDisabled])

  const isError = webhooksByAccountError || webhooksByProjectError || globalWebhooksError
  if (isError) {
    return <ErrorPanel msg="Something went wrong loading webhooks. Sorry about that!" />
  }

  const isFetching = webhooksByAccountFetching || webhooksByProjectFetching || globalWebhooksFetching
  const webhooks = [...webhooksByAccount, ...webhooksByProject, ...globalWebhooks]

  return (
    <React.Fragment>
      <CardBody>
        {!isFetching &&
          webhooks.map((webhook) => {
            return (
              <WebhookRow
                key={`webhooklist-${webhook.slug}-${webhook?.subscription?.owner || ''}-${webhook?.subscription?.project_slug || ''}`}
                webhook={webhook}
                workspaceSlug={webhook.subscription?.owner || ''}
                openWebhookModal={onOpen}
                onDelete={handleMutate}
              />
            )
          })}
        <ListDefaults isLoading={isFetching} nItems={webhooks.length} itemName="webhook" isFail={isError} />
      </CardBody>
      <MayhemPagination isLoading={isFetching} total={webhooksByAccountCount || webhooksByProjectCount || globalWebhooksCount} />
      {lastSelectedWebhook && <CreateWebhookModal isOpen={isOpen} onClose={onClose} onHookMutate={handleMutate} />}
    </React.Fragment>
  )
}
