import { useCallback, useEffect } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { skipToken } from '@reduxjs/toolkit/query'
import { SerializedError } from '@reduxjs/toolkit'

import { FormCodeRunCommand, FormCodeRunCommandNetwork, FormCodeRunConfig } from '../run-code-config/utils'

import { DockerImageConfig, DockerImageMetadata, parseDockerImageConfig } from '../../util/docker'

import { useDebounce } from '@/hooks'
import { useGetDockerImageConfigQuery } from '@/redux/api/docker'
import { PartialOpaque } from '@/opaque'
import { CoreCodeRunConfig } from '@/features/run-code-config/CoreCodeRunConfig'

export const useImageConfig = (
  initialConfig: PartialOpaque<CoreCodeRunConfig>,
  { getValues, reset, setError, clearErrors, setValue, formState: { defaultValues } }: UseFormReturn<FormCodeRunConfig>
) => {
  const image = useDebounce(getValues('dockerImage'), 2000)
  const { data, error: dockerImageError, isLoading } = useGetDockerImageConfigQuery(image ? { image: image } : skipToken)

  const resetCommand = useCallback(
    (metadata: DockerImageMetadata) => {
      const activeCommands = getValues('cmds')

      const commandEmpty = !activeCommands
      const commandUntouched = defaultValues?.dockerImage !== image && activeCommands === defaultValues?.cmds

      if (!commandEmpty && !commandUntouched) {
        // use has modified the command, do not touch it
        return
      }
      const [command, settings] = mapManifestToCommand(metadata)
      const { dockerImage: _, cmds: __, cmdSettings: ___, projectNameOrSlug: ____, ...restValues } = defaultValues || {}

      let projectNameOrSlug = getValues('projectNameOrSlug')
      if (!projectNameOrSlug) {
        const imageTokens = image.replace('$MAYHEM_DOCKER_REGISTRY/', '').split(':')[0].split('/')
        projectNameOrSlug = imageTokens[imageTokens.length - 1]
      }

      const targetNameOrSlug = getValues('targetNameOrSlug') || projectNameOrSlug

      const update = {
        dockerImage: image,
        cmds: `$ ${command}`,
        projectNameOrSlug,
        cmdSettings: [settings],
        targetNameOrSlug
      }

      reset({ ...restValues, ...update }, { keepValues: true })
      setValue('cmds', update.cmds)
      setValue('cmdSettings', [settings])
      setValue('projectNameOrSlug', update.projectNameOrSlug)
      setValue('targetNameOrSlug', update.targetNameOrSlug)
    },
    [reset, getValues, defaultValues, setValue, image]
  )

  useEffect(() => {
    if (isLoading) {
      // Manifest is loading, wait for it
      return
    }

    if (dockerImageError) {
      let message

      const serializedError = dockerImageError as unknown as SerializedError
      if (serializedError?.message) {
        message = serializedError?.message
      }

      const baseError = dockerImageError as unknown as { data?: { message?: string } }
      if (baseError?.data?.message) {
        message = baseError?.data?.message
      }

      setError('dockerImage', { message })
    }

    if (!data) {
      return
    }

    const metadata = parseDockerImageConfig(data as Partial<DockerImageConfig>)

    if (!metadata.command) {
      // Could not resolve the command
      return
    }

    resetCommand(metadata)
  }, [data, dockerImageError, isLoading, resetCommand, setError, clearErrors])
}

const mapManifestToNetwork = (manifest: DockerImageMetadata): FormCodeRunCommandNetwork | undefined => {
  if (!manifest.network) {
    return undefined
  }
  return {
    url: manifest.network.url,
    timeout: '2',
    client: false
  }
}

const mapManifestToCommand = (manifest: DockerImageMetadata): [string, FormCodeRunCommand] => {
  return [
    manifest.command || '',
    {
      network: mapManifestToNetwork(manifest),
      // TODO(kostas): Research additional fields that could be derived from the manifest
      env: [],
      afl: 'automatic',
      libfuzzer: 'automatic',
      honggfuzz: 'automatic',
      sanitizer: 'automatic'
    }
  ]
}
