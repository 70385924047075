import DeleteAction from './DeleteAction'

import { useDeleteOrgMemberMutation } from '@/redux/api/workspace'

interface Props {
  onDelete?: () => void
  userSlug: string
  workspaceSlug: string
  currentUserHasPermission: boolean
}

export default function UserDeleteAction({ onDelete, userSlug, workspaceSlug, currentUserHasPermission }: Props) {
  const [deleteOrgMember, { isLoading: isDeleting }] = useDeleteOrgMemberMutation()

  return (
    <DeleteAction
      entity="User"
      currentUserHasPermission={currentUserHasPermission}
      isDeleting={isDeleting}
      onDelete={onDelete}
      deleteAction={async () => {
        await deleteOrgMember({ owner: workspaceSlug, userSlug }).unwrap()
      }}
    />
  )
}
