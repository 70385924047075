import { useEffect, useMemo } from 'react'

import { mayhemfileYamlDeserialize } from './Mayhemfile'
import { CodeRunCreateForm } from './CodeRunCreateForm'

import { useAppSelector, useQuery } from '@/hooks'

import { cleanInteger } from '@/util/numbers'
import { selectNewCodeRun } from '@/features/workspace/create-code-run.slice'
import { opaque, PartialOpaque } from '@/opaque'
import { CoreCodeRunConfig } from '@/features/run-code-config/CoreCodeRunConfig'

import { getWorkspacePageTitle } from '@/titles'
import { decodeMayhemFile } from '@/features/run-code-config/hooks'

export interface CodeProjectCreatePageProps {
  workspaceSlug: string
}

export const CodeRunCreatePage = ({ workspaceSlug }: CodeProjectCreatePageProps) => {
  useEffect(() => {
    document.title = getWorkspacePageTitle(workspaceSlug, 'New code project')
  }, [workspaceSlug])

  const query = useQuery()

  // Initialize the form from query parameters
  const { dockerImage } = useAppSelector(selectNewCodeRun)

  const initialConfig = useMemo(() => {
    const mayhemfileEncoded = query.get('mayhemfile')
    if (mayhemfileEncoded) {
      // build initial configuration from query parameters
      const decodedMayhemFile = decodeMayhemFile(mayhemfileEncoded)

      return mayhemfileYamlDeserialize(decodedMayhemFile, { workspaceSlug })
    }

    // build initial configuration from redux state
    return opaque<PartialOpaque<CoreCodeRunConfig>>({
      workspaceSlug: workspaceSlug,
      image: dockerImage
    })
    // We want initialConfig to be created once when the component is initialized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reference = useMemo(() => {
    const projectSlug = query.get('project')
    const targetSlug = query.get('target')
    const run = query.get('run')
    if (projectSlug && targetSlug && run) {
      return { workspaceSlug, projectSlug, targetSlug, run: cleanInteger(run) }
    }
    return undefined
  }, [query, workspaceSlug])

  return <CodeRunCreateForm workspaceSlug={workspaceSlug} initialConfig={initialConfig} reference={reference} />
}
