import { Box, BoxProps, Center, StylesProvider, Text, useMultiStyleConfig, useStyles } from '@chakra-ui/react'
import React from 'react'

import { CheckIcon, CloseIcon, MinusIcon } from '../../icons'

export type ProgressIndicatorVariants = 'pending' | 'stopped' | 'failed' | 'ended' | 'running' | 'preparing' | 'deleting' | 'new' | 'unknown'

export type ProgressIndicatorProps = BoxProps & {
  variant: ProgressIndicatorVariants
  size: 'sm' | 'md' | 'lg'
}

function ProgressIcon(props: { variant: ProgressIndicatorVariants }): JSX.Element | null {
  const { variant } = props
  const styles = useStyles()
  if (variant === 'ended') {
    return <CheckIcon __css={styles.icon} />
  } else if (variant === 'failed') {
    return <CloseIcon __css={styles.icon} />
  } else if (variant === 'stopped') {
    return <MinusIcon __css={styles.icon} />
  } else if (variant === 'unknown') {
    return (
      <Text position="absolute" fontWeight="semibold" color="white">
        ?
      </Text>
    )
  } else if (variant === 'new') {
    return (
      <Text fontSize="7.5px" fontWeight="semibold" color="chakra-body-bg" position="absolute">
        NEW
      </Text>
    )
  }
  return null
}

function ProgressIndicatorInner({ variant, size, ...rest }: ProgressIndicatorProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const styles = useMultiStyleConfig('ProgressIndicator', { size, variant })

  return (
    <Center __css={styles.container} ref={ref} {...rest}>
      <Box __css={styles.outer}>
        <Center height="full">
          <Box __css={styles.inner} />
          <StylesProvider value={styles}>
            <ProgressIcon variant={variant} />
          </StylesProvider>
        </Center>
      </Box>
      <Box __css={styles.spinner} />
    </Center>
  )
}

export const ProgressIndicator = React.forwardRef(ProgressIndicatorInner)
