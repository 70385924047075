import { Heading, ListItem, OrderedList, Stack, Text } from '@chakra-ui/react'

import { CodeBlock } from '../../components/CodeBlock/CodeBlock'

import { DownloadFileButton } from '../../components/DownloadFileButton'

const StepsToReproduce = ({ workspaceSlug, projectSlug, targetSlug, sha256, reproCmdline, downloadFileName }: Props): JSX.Element => {
  // just show the download button if there's no repro command
  if (!reproCmdline) {
    return (
      <Text>
        Download the test case file:&nbsp;
        <DownloadFileButton
          aria-label="Download Test Case File"
          href={`/api/v2/owner/${workspaceSlug}/project/${projectSlug}/target/${targetSlug}/testcase/${sha256 || ''}/file`}
          download={downloadFileName}
        />
      </Text>
    )
  }

  const fixedCommand = (reproCmdline || '').replace(sha256 || '', downloadFileName || '')

  return (
    <Stack>
      <Heading size="md">Steps to Reproduce</Heading>
      <OrderedList paddingLeft={6} spacing={6}>
        <ListItem>
          Download the test case file:&nbsp;
          <DownloadFileButton
            aria-label="Download Test Case File"
            href={`/api/v2/owner/${workspaceSlug}/project/${projectSlug}/target/${targetSlug}/testcase/${sha256 || ''}/file`}
            download={downloadFileName}
          />
        </ListItem>
        <ListItem>Place the file in your working directory.</ListItem>
        <ListItem>In your terminal, run the following command:</ListItem>
      </OrderedList>
      <CodeBlock>{fixedCommand}</CodeBlock>
    </Stack>
  )
}

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  sha256: string | undefined
  reproCmdline: string | null | undefined
  downloadFileName: string | undefined
}

export default StepsToReproduce
