import { Box, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react'

import { ProgressIndicatorProps } from '../../components/ProgressIndicator/ProgressIndicator'

import { RunStatusHover } from '../run/RunStatusHover'
import { RunStatusSpinner } from '../run/RunStatusSpinner'

export const RunRowStatus = ({
  indicatorSize = 'sm',
  isProjectLatestActiveRun = false,
  workspaceSlug,
  projectSlug,
  targetSlug,
  runNumber
}: Props) => {
  return (
    <Popover key={`${workspaceSlug}_${projectSlug}_${targetSlug}_${runNumber}`} trigger="hover">
      <PopoverTrigger>
        <Box data-testid="run-status">
          <RunStatusSpinner indicatorSize={indicatorSize} {...{ workspaceSlug, projectSlug, targetSlug, runNumber }} />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverBody>
          <RunStatusHover showLatestActiveRunHeader={isProjectLatestActiveRun} {...{ workspaceSlug, projectSlug, targetSlug, runNumber }} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
  indicatorSize?: ProgressIndicatorProps['size']
  isProjectLatestActiveRun?: boolean
}
