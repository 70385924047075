import { ComponentStyleConfig } from '@chakra-ui/react'
import { statAnatomy } from '@chakra-ui/anatomy'

export const Stat: ComponentStyleConfig = {
  parts: statAnatomy.keys,
  baseStyle: {
    container: {
      bg: 'chakra-body-bg',
      border: '1px',
      borderRadius: 'md',
      borderColor: 'chakra-border-color'
    },
    number: {
      fontSize: '3xl'
    }
  },
  // custom sizes need to be set to properly change the label font size
  sizes: {
    sm: {
      label: { fontSize: 'sm' },
      number: { fontSize: 'xl' },
      helpText: { fontSize: 'xs' }
    },
    md: {
      label: { fontSize: 'md' },
      number: { fontSize: '2xl' },
      helpText: { fontSize: 'sm' }
    },
    lg: {
      label: { fontSize: 'lg' },
      number: { fontSize: '3xl' },
      helpText: { fontSize: 'md' }
    }
  }
}
