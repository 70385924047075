import { Card, CardBody, CardHeader, Flex, Heading, Stack, Text } from '@chakra-ui/react'

import { useGetRunQuery } from '@/redux/api/runs'
import MayhemTableLoader from '@/components/MayhemTableLoader'
import EventsList from '@/features/run-code-log/EventsList'

interface Props {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  runNumber: number
}

export function ApiRunLog({ workspaceSlug, projectSlug, targetSlug, runNumber }: Props) {
  const { isLoading: isLoadingRun, data: run } = useGetRunQuery({ owner: workspaceSlug, projectSlug, targetSlug, runNumber })

  const { cli_version: cliVersion } = run || {}
  const { spec_source: specSource, url: targetUrl } = run?.api_run || {}

  if (isLoadingRun) {
    return <MayhemTableLoader />
  }

  return (
    <Stack gap={4}>
      <Card>
        <CardHeader>
          <Heading variant="cardHeading">Run Details</Heading>
        </CardHeader>
        <CardBody p={8}>
          <Flex gap={16} wrap="wrap">
            <Stack>
              <Heading size="md">Target URL</Heading>
              <Text>{targetUrl}</Text>
            </Stack>
            <Stack>
              <Heading size="md">CLI Version</Heading>
              <Text>{cliVersion}</Text>
            </Stack>
            <Stack>
              <Heading size="md">Specification Type</Heading>
              <Text>{specSource}</Text>
            </Stack>
          </Flex>
        </CardBody>
      </Card>
      <EventsList {...{ workspaceSlug, projectSlug, targetSlug, runNumber }} />
    </Stack>
  )
}
