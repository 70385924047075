import { Flex, IconButton, Skeleton } from '@chakra-ui/react'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

import { memo } from 'react'

import { Link } from 'wouter'

import { useGetDefectNavigationQuery } from '../../redux/api/defects'

interface DefectNavigationProps {
  workspaceSlug: string
  projectSlug: string
  targetSlug: string
  defectNumber: number
  runNumber: number | null | undefined
}

function DefectNavigationInner({ workspaceSlug, projectSlug, targetSlug, runNumber, defectNumber }: DefectNavigationProps) {
  const { data: navigationData, isFetching } = useGetDefectNavigationQuery({
    owner: workspaceSlug,
    projectSlug,
    targetSlug,
    runNumber: runNumber ? runNumber : undefined,
    defectNumber
  })

  if (!navigationData) {
    return <></>
  }

  const previousLink =
    `/${workspaceSlug}/${projectSlug}/${targetSlug}/-/defects/${navigationData?.previous_defect_number}` + (runNumber ? `/runs/${runNumber}` : '')

  const nextLink =
    `/${workspaceSlug}/${projectSlug}/${targetSlug}/-/defects/${navigationData?.next_defect_number}` + (runNumber ? `/runs/${runNumber}` : '')
  return (
    <Skeleton isLoaded={!isFetching}>
      <Flex flexDirection={{ base: 'column', lg: 'row' }} gap="2">
        {navigationData.previous_defect_number && (
          <Link href={previousLink}>
            <IconButton variant="outline" icon={<ChevronLeftIcon />} aria-label="Previous Defect" />
          </Link>
        )}
        {navigationData.next_defect_number && (
          <Link to={nextLink}>
            <IconButton variant="outline" icon={<ChevronRightIcon />} aria-label="Next Defect" />
          </Link>
        )}
      </Flex>
    </Skeleton>
  )
}

export const DefectNavigation = memo(DefectNavigationInner)
