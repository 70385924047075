import { useEffect } from 'react'

import { getWorkspaceSettingsPageTitle } from '../../titles'

import { useAppSelector } from '../../hooks'

import { getCurrentUserInfo } from '../auth/utils'

import { ApiTokenList } from './ApiTokenList'

export function UserSettingsAPITokensPage() {
  const { userSlug: currentUserSlug } = useAppSelector((state) => getCurrentUserInfo(state) || {})

  useEffect(() => {
    document.title = getWorkspaceSettingsPageTitle(currentUserSlug, 'API Tokens')
  }, [currentUserSlug])

  return <ApiTokenList userSlug={currentUserSlug} />
}
