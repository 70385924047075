import { ChangeEvent } from 'react'
import { useLocation } from 'wouter'

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardProps,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  Stack,
  Text
} from '@chakra-ui/react'

import AddIcon from '@material-design-icons/svg/sharp/add.svg?react'
import SearchIcon from '@material-design-icons/svg/sharp/search.svg?react'

import { useAppDispatch, useAppSelector } from '@/hooks'
import { selectNewCodeRun, setDockerImage } from '@/features/workspace/create-code-run.slice'
import { link } from '@/features/workspace/utils'
import { DockerImageSelectionModal } from '@/features/docker-registry/DockerImageSelectionModal'

const DockerImageExample = 'forallsecure/lighttpd:vulnerable'

export const CreateCodeRunCard = ({ workspaceSlug, ...cardProps }: CreateCodeRunCardProps) => {
  const { isPublicDeployment } = useAppSelector((state) => state.config)

  const [_, setLocation] = useLocation()

  const dispatch = useAppDispatch()
  const { dockerImage } = useAppSelector(selectNewCodeRun)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setDockerImage(event.target.value))
  }

  const handleSubmit = () => {
    setLocation(link(workspaceSlug, '-', 'create-code-run'))
  }

  return (
    <Card {...cardProps}>
      <CardHeader>
        <Heading variant="cardHeading">Code Testing</Heading>
      </CardHeader>
      <CardBody padding={4}>
        <Stack spacing={8}>
          <Text>AI-generated behavioral testing of your code</Text>
          <FormControl>
            <FormLabel fontSize="md" fontWeight="semibold">
              Container Image
            </FormLabel>
            <Stack direction="row" gap={2}>
              <Input placeholder={DockerImageExample} value={dockerImage || ''} onChange={handleInputChange} />
              {!isPublicDeployment && (
                <DockerImageSelectionModal
                  trigger={
                    <IconButton
                      variant="outline"
                      color="gray.300"
                      icon={<Icon as={SearchIcon} color="gray.500" />}
                      aria-label="select-docker-image"
                    />
                  }
                  onImageSelect={(image) => dispatch(setDockerImage(`$MAYHEM_DOCKER_REGISTRY/${image}`))}
                />
              )}
            </Stack>
            <FormHelperText fontSize="md">Leave the default value if you want to see Mayhem run on an example.</FormHelperText>
          </FormControl>
        </Stack>
      </CardBody>
      <CardFooter justifyContent="end">
        <Button leftIcon={<AddIcon />} onClick={handleSubmit}>
          Add Code
        </Button>
      </CardFooter>
    </Card>
  )
}

export type CreateCodeRunCardProps = CardProps & {
  workspaceSlug: string
}
