import PermissionDeleteAction from './PermissionDeleteAction'

import { useDeleteProjectTeamMemberMutation } from '@/redux/api/teams'

interface Props {
  onDelete: () => void
  teamSlug: string
  workspaceSlug: string
  projectSlug: string
  currentUserHasPermission: boolean
}

export default function TeamPermissionDeleteAction({ onDelete, teamSlug, projectSlug, workspaceSlug, currentUserHasPermission }: Props) {
  const [deleteProjectTeam, { isLoading: isDeleting }] = useDeleteProjectTeamMemberMutation()

  return (
    <PermissionDeleteAction
      entity="Team"
      slug={teamSlug}
      currentUserHasPermission={currentUserHasPermission}
      isDeleting={isDeleting}
      projectSlug={projectSlug}
      onDelete={onDelete}
      deleteAction={async () => {
        await deleteProjectTeam({ owner: workspaceSlug, projectSlug, teamSlug }).unwrap()
      }}
    />
  )
}
